import { Feedback, FeedbackType } from "@cpe/models/feedback";
import { FeedbackService } from "@cpe/services/feedback";
import { SuccessCodeRegex } from "@cpe/services/fetch-util";
import merge from "lodash/merge";
import { useEffect, useState } from "react";
import { LocalStorageType } from "../../../../constants/storage";
import { useFeedback, useStorage, useUser } from "../../../../hooks";
import { useAppDispatch } from "../../../../hooks/store";
import { setStorageItem, storageKeys } from "../../../../store/storage";

export interface PopupMessagesType {
  feebacksToShow: Feedback[];
  setRemoteDismiss: (feedbackType: FeedbackType) => Promise<void>;
  setRemoteAccept: (feedbackType: FeedbackType) => Promise<void>;
  setSessionDismiss: (feedbackType: FeedbackType) => Promise<void>;
  isLoadingFeedbacks: boolean;
}

export const usePopupMessages = (): PopupMessagesType => {
  const [feebacksToShow, setFeedbacksToShow] = useState<Feedback[]>([]);
  const { user } = useUser();
  const { localStorage } = useStorage();
  const dispatch = useAppDispatch();
  const { feedbacks, loading: isLoadingFeedbacks } = useFeedback();
  const setRemoteDismiss = async (feedbackType: FeedbackType) => {
    const response = await FeedbackService.dismiss(feedbackType);
    if (response.status.toString().match(SuccessCodeRegex)) {
      setFeedbacksToShow(feebacksToShow.filter(feedback => feedback.type !== feedbackType));
    }
  };
  const setRemoteAccept = async (feedbackType: FeedbackType) => {
    const response = await FeedbackService.accept(feedbackType);
    if (response.status.toString().match(SuccessCodeRegex)) {
      setFeedbacksToShow(feebacksToShow.filter(feedback => feedback.type !== feedbackType));
    }
  };
  const setSessionDismiss = async (feedbackType: FeedbackType) => {
    dispatch(
      setStorageItem({
        key: LocalStorageType.POPUPS_SESSION,
        value: merge(localStorage?.["popups-session"], { [btoa(user.email)]: { [feedbackType]: true } }),
        type: storageKeys.localStorage
      })
    );
    setFeedbacksToShow(feebacksToShow.filter(feedback => feedback.type !== feedbackType));
  };
  useEffect(() => {
    if (feedbacks?.length) {
      const userSessionDismissed = localStorage?.["popups-session"]?.[btoa(user.email)];
      setFeedbacksToShow(feedbacks.filter(feedback => userSessionDismissed?.[feedback.type] !== true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbacks]);

  return {
    feebacksToShow,
    setRemoteDismiss,
    setRemoteAccept,
    setSessionDismiss,
    isLoadingFeedbacks
  };
};
