import { AccreditationLicense } from "@cpe/models/accreditation-license";
import { User, UserRole } from "@cpe/models/user";
import { createSlice } from "@reduxjs/toolkit";
import { enableAccreditationLicenseThunk, UserAccreditationLicenseThunk, UserThunk } from "./thunks";

interface UserState {
  user: User;
  accreditationLicenses: Record<string, AccreditationLicense>;
  loading: boolean;
  error: string | null;
}

const emptyUser = {
  username: "",
  email: "",
  firstName: "",
  lastName: "",
  role: "STUDENT" as UserRole,
  title: "",
  timezone: "America/Chicago",
  language: "",
  shoppingCart: false,
  isAuthenticated: false,
  studentHid: "",
  phoneNumber: "",
  birthDate: "",
  ctEnabled: false
};

export const initialState: UserState = {
  user: emptyUser,
  accreditationLicenses: {},
  loading: false,
  error: null
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: state => {
      state.user.isAuthenticated = true;
      state.loading = false;
    },
    clearUser: _state => {
      _state = initialState;
    },
    enableAccredutationLicense: _state => {
      _state = initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(UserThunk.fulfilled, (state, action) => {
      const user = action.payload as User;
      if (!user) {
        state.loading = false;
        state.user = emptyUser;
        state.error = "user not authenticated";
        return;
      }
      state.user = user;
      state.user.isAuthenticated = true;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(UserThunk.rejected, state => {
      state.loading = false;
      state.user = emptyUser;
      state.error = "user not authenticated";
    });
    builder.addCase(UserThunk.pending, state => {
      state.user = emptyUser;
      state.error = "";
      state.loading = true;
    });
    builder.addCase(UserAccreditationLicenseThunk.fulfilled, (state, action) => {
      const accreditationLicense = action.payload as AccreditationLicense;
      state.accreditationLicenses[action.meta.arg.licenseId] = accreditationLicense;
      state.error = "";
      state.loading = false;
    });
    builder.addCase(UserAccreditationLicenseThunk.rejected, state => {
      state.loading = false;
      state.error = "error";
    });
    builder.addCase(UserAccreditationLicenseThunk.pending, state => {
      state.error = "";
      state.loading = true;
    });
    builder.addCase(enableAccreditationLicenseThunk.fulfilled, state => {
      state.error = "";
      state.loading = false;
    });
    builder.addCase(enableAccreditationLicenseThunk.rejected, (state, action) => {
      state.accreditationLicenses[action.meta.arg.licenseId].enabled = !action.meta.arg.enable;
      state.error = "error";
    });
    builder.addCase(enableAccreditationLicenseThunk.pending, (state, action) => {
      state.error = "";
      state.accreditationLicenses[action.meta.arg.licenseId].enabled = action.meta.arg.enable;
      state.loading = true;
    });
  }
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
