import { CartItemModel, CartModel } from "@cpe/models/cart";
import { ErrorResponse } from "@cpe/services/fetch-util";
import { CartService } from "@cpe/services/store/cart";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const Cart = createAsyncThunk("cart/items/get", async () => (await CartService.getCart()).parsedBody);

export const CartAdd = createAsyncThunk<(ErrorResponse & CartModel) | undefined, CartItemModel, any>(
  "cart/items/add",
  async (cartItem: CartItemModel) => (await CartService.addToCart(cartItem)).parsedBody
);

export const CartRemove = createAsyncThunk<(ErrorResponse & CartModel) | undefined, string, any>(
  "cart/items/remove",
  async (productId: string) => (await CartService.remove(productId)).parsedBody
);
