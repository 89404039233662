import { Button } from "@beckersdp/becker-ds";
import { ButtonProps } from "@beckersdp/becker-ds/dist/button/types";
import { Promo } from "@cpe/models/promos";
import { SuccessCodeRegex } from "@cpe/services/fetch-util";
import { PromoService } from "@cpe/services/store/promo";
import React, { FC, useState } from "react";
import { ComplianceUpgradeModal } from "../compliance-upgrade-modal";

export type UpsellButtonProps = {
  autoRedirectProduct?: Promo["productType"];
  forceUpgradeModal?: boolean;
} & Omit<ButtonProps, "loading">;

export const UpsellButton: FC<UpsellButtonProps> = props => {
  const { autoRedirectProduct, forceUpgradeModal, onClick, ...restOfprops } = props;
  const [isLoadingPromos, setIsLoadingPromos] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [promos, setPromos] = useState<Promo[]>([]);
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsLoadingPromos(true);
    onClick && onClick(event);
    const response = await PromoService.getPromos();
    if (response.status.toString().match(SuccessCodeRegex)) {
      const promos = response.parsedBody?.items as Promo[];
      let product: Promo | undefined;
      if (autoRedirectProduct) {
        product = promos.filter(promo => [autoRedirectProduct].includes(promo.productType))[0];
      } else {
        product = promos.filter(promo => ["PRIME_DIRECT"].includes(promo.productType))[0];
      }
      if (product) {
        if (product.link && product.link?.external) {
          window.open(product.link.url, "_blank");
        } else if (product.link) {
          window.location.href = product.link.url;
        }
      } else {
        setPromos(promos);
        setShowUpgradeModal(true);
      }
    }
    setIsLoadingPromos(false);
  };

  return (
    <>
      <Button onClick={handleClick} loading={isLoadingPromos} {...restOfprops} />
      <ComplianceUpgradeModal
        promos={promos}
        visible={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
        forceUpgradeModal={forceUpgradeModal}
      />
    </>
  );
};
