import { ForgotPasswordTemplate, ProductLogo } from "@beckersdp/becker-ds";
import { RecoverResponse } from "@cpe/services/authentication";
import { HttpResponse } from "@cpe/services/fetch-util";
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle } from "styled-components";
import { useTranslation } from "../../i18n";
import { footerLinks } from "../sign-in/components/common-template/footer-links";
import { EmailSent } from "./email-sent";
import { ForgotPasswordForm } from "./forgot-password-form";

type Step = "forgot" | "emailSent" | "beckerRedirect" | "reset" | "resetSuccess";
type StepPanels = { [P in Step]?: JSX.Element };

const ResetPassword: FC = () => {
  const { t } = useTranslation(["recoverPassword"]);
  const [step, setStep] = useState<Step>("forgot");
  const [email, setEmail] = useState("");

  const recoverSubmit = async (response: HttpResponse<RecoverResponse>, email: string) => {
    const redirectUrl = response?.parsedBody?.redirectUrl;

    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      setEmail(email);
      setStep("emailSent");
    }
  };
  const stepPanels: StepPanels = {
    forgot: <StyledForgotPasswordForm onSubmit={recoverSubmit} />,
    emailSent: <EmailSent email={email} />
  };
  const config = {
    mainPanelContent: stepPanels[step],
    logo: (
      <StyledProductLogo
        aria-label="CPE"
        Logo={require("@beckersdp/becker-ds/dist/assets/eds-images/logos/svg/cpe.svg")}
      />
    ),
    footer: footerLinks(t("recoverPassword:copyrights")),
    step
  };
  return (
    <>
      <Helmet>
        <title>{t("recoverPassword:title")}</title>
      </Helmet>
      <StyledForgotPasswordTemplate {...config} />
      <GlobalStyles />
    </>
  );
};

const StyledForgotPasswordTemplate = styled(ForgotPasswordTemplate)`
  height: 100vh;
  overflow: hidden;
`;

const StyledForgotPasswordForm = styled(ForgotPasswordForm)`
  width: 100%;
`;

const StyledProductLogo = styled(ProductLogo)`
  width: 100%;
`;

const GlobalStyles = createGlobalStyle`
  
  body, 
  html, #root {
    height: 100%;
  }
`;

export default ResetPassword;
