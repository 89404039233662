import { NavigationElement, NavigationGroup, NavigationItem } from "@cpe/models/navigation-link";
import React, { FC } from "react";
import styled from "styled-components";
import { SubMenuGroup } from "./sub-menu-group";
import { SubMenuLink } from "./sub-menu-link";
interface Props {
  items: NavigationElement;
}
export const SubMenu: FC<Props> = props => {
  const { items } = props;
  return (
    <Container>
      {items.map(item => {
        if ((item as NavigationGroup)?.items?.length > 0) {
          return <SubMenuGroup key={item.label} title={item.label} item={item as NavigationGroup} />;
        }
        return (
          <React.Fragment key={item.label}>
            <SubMenuLink item={item as NavigationItem} />
          </React.Fragment>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  padding: 8px 8px 0 8px;
  display: flex;
  gap: 16px;
`;
