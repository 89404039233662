import * as FetchUtil from "../fetch-util";

export type AvailableUserSettingsKeys = "DONT_WARN_CREDITS_ALREADY_EARNED" | "HIDE_MY_COURSES_TOOLTIP";
export type UserSettingsType = { [P in AvailableUserSettingsKeys]?: string };

export class UserSettingsService {
  static get(): Promise<FetchUtil.HttpResponse<UserSettingsType>> {
    return FetchUtil.get(userSettingsApiMatchers.userSettings);
  }
  static update(keys: UserSettingsType): Promise<FetchUtil.HttpResponse<UserSettingsType>> {
    return FetchUtil.put(userSettingsApiMatchers.userSettings, { ...keys });
  }
  static delete(key: AvailableUserSettingsKeys): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.Delete(userSettingsApiMatchers.userSettingsKey.replace(":key", key), {});
  }
}

export const userSettingsApiMatchers = {
  userSettings: "/api/v1/userSettings",
  userSettingsKey: "/api/v1/userSettings/:key"
};
