import { Dropdown } from "@beckersdp/becker-ds";
import { NavigationLink } from "@cpe/models/navigation-link";
import React, { FC } from "react";
import styled from "styled-components";
import { SessionStorageType } from "../../../../../../constants/storage";
import { useTranslation } from "../../../../../../i18n";
import { PageQALabels } from "../../../../../../page-objects/components/page/qa-labels";
import { useComplianceSectionContext } from "../../../../../../pages/home/components/compliance-section/compliance-section-provider";
import { availableRoutes } from "../../../../../../router/routes";
import { useComplianceDisclaimerContext } from "../../../../../compliance-legal-disclaimer/compliance-disclaimer-provider";
import { CompliancePopover } from "../compliance-popover/compliance-popover";
import { LinkItem } from "./link-item";
import { CustomDropdownMenu, DownChevronIcon, MenuText, NavOption } from "./styles";
export const MoreNavigation: FC<{ items: NavigationLink[] }> = props => {
  const { items } = props;
  const { t } = useTranslation(["context_app"]);
  const hasComplianceLink = items.filter(
    link => link.description === "Compliance" || link.url === availableRoutes.compliance
  )[0];
  const { shouldShowComplianceDisclaimer, openComplianceDisclaimer } = useComplianceDisclaimerContext();
  const showCompliancePopover =
    hasComplianceLink && sessionStorage[SessionStorageType.HOME_COMPLIANCE_OLD_TRACKER_POPOVER];
  const complianceCredits = useComplianceSectionContext();

  const hasLicenses = complianceCredits?.complianceCredits && complianceCredits?.complianceCredits?.length > 0;

  const render = () => {
    return (
      <CustomSpan role="listitem">
        <Dropdown
          placement="bottom-start"
          data-qa-label={PageQALabels.moreDropdown}
          menu={
            <MoreMenu>
              <>
                {items.map((link, index) => {
                  if (link.children) {
                    return link.children.map((child_link, child_index) => {
                      const isMyComplianceLink = link.url === availableRoutes.compliance;
                      return (
                        <StyledCustomOption key={`${index}_${child_index}`}>
                          <LinkItem
                            key={index}
                            role="listitem"
                            link={child_link}
                            data-qa-label={child_link.permission}
                            onClick={event => {
                              if (hasLicenses && isMyComplianceLink && shouldShowComplianceDisclaimer()) {
                                event.preventDefault();
                                openComplianceDisclaimer();
                                return;
                              }
                            }}
                          >
                            {child_link.description}
                          </LinkItem>
                        </StyledCustomOption>
                      );
                    });
                  }
                  const isComplianceLink = link.description === "Compliance" || link.url === availableRoutes.compliance;
                  return (
                    <StyledCustomOption key={index}>
                      <LinkItem
                        key={index}
                        role="listitem"
                        link={link}
                        data-qa-label={link.permission}
                        onClick={event => {
                          if (hasLicenses && isComplianceLink && shouldShowComplianceDisclaimer()) {
                            event.preventDefault();
                            openComplianceDisclaimer();
                            return;
                          }
                        }}
                      >
                        {link.description}
                      </LinkItem>
                    </StyledCustomOption>
                  );
                })}
              </>
              <></>
            </MoreMenu>
          }
        >
          <NavOption>
            <Container>
              <CustomMenuText>{t("context_app:more")}</CustomMenuText>
              <DownChevronIcon />
            </Container>
          </NavOption>
        </Dropdown>
      </CustomSpan>
    );
  };
  if (showCompliancePopover) {
    return <CustomCompliancePopover role="list-item">{render()}</CustomCompliancePopover>;
  }
  return render();
};

const CustomCompliancePopover = styled(CompliancePopover)`
  height: 100%;
`;

const Container = styled.div`
  height: 63px;
  display: flex;
  align-items: center;
`;

const StyledCustomOption = styled.div`
  span {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    font-family: ${props => props.theme.fontFamily.primary};
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: ${props => props.theme.colors.brand01[700]};
    background-color: ${props => props.theme.colors.white};
    border: none;
    padding: 0 10px 20px 10px;

    &:hover {
      text-decoration: underline;
      background-color: ${props => props.theme.colors.white};
      border: none;
    }

    a {
      height: auto;
    }
  }
`;

const CustomMenuText = styled(MenuText)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamily.primary};
`;

const CustomSpan = styled.span`
  display: flex;
  align-items: center;
  top: 2px;
  position: relative;
  height: 100%;
  width: 80px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  &:hover {
    cursor: pointer;
  }
`;

const MoreMenu = styled(CustomDropdownMenu)`
  top: 0px !important;
`;
