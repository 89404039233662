import { Course } from "@cpe/models/course";
import { SectionRequest } from "@cpe/models/section";
import { Courses } from "@cpe/services/courses/sections";
import { ErrorResponse } from "@cpe/services/fetch-util";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const Sections = createAsyncThunk<(ErrorResponse & SectionRequest[]) | undefined, void, any>(
  "courses/section",
  async () => (await Courses.sections()).parsedBody
);
export const SectionCourses = createAsyncThunk<(ErrorResponse & Course[]) | undefined, any, any>(
  "section/courses",
  async ({ fetchUrl }) => (await Courses.recomendations(fetchUrl)).parsedBody
);
