import { NavigationLink, NavigationLinkResponse } from "@cpe/models/navigation-link";
import * as FetchUtil from "../fetch-util";

export interface NavigationLinksResponse {
  navigationLinks: NavigationLink[];
  helpLinks: NavigationLink[];
}

export class NavigationLinksService {
  static get(): Promise<FetchUtil.HttpResponse<NavigationLinksResponse>> {
    return FetchUtil.get(`/api/v1/ux/navigation`);
  }
  static getLinks(): Promise<FetchUtil.HttpResponse<NavigationLinkResponse>> {
    return FetchUtil.get(`/api/v1/ux/navigation`);
  }
}
