import { Feedback, FeedbackType } from "@cpe/models/feedback";
import * as FetchUtil from "../fetch-util";

export interface FeedbackResponse {
  requests: Feedback[];
}

export class FeedbackService {
  static get(): Promise<FetchUtil.HttpResponse<FeedbackResponse>> {
    return FetchUtil.get(`/api/v1/feedback`);
  }
  static dismiss(type: FeedbackType): Promise<FetchUtil.HttpResponse<FeedbackResponse>> {
    return FetchUtil.Delete(`/api/v1/feedback/${type}/read`, {});
  }
  static accept(type: FeedbackType): Promise<FetchUtil.HttpResponse<FeedbackResponse>> {
    return FetchUtil.put(`/api/v1/feedback/${type}/read`, {});
  }
}
