export enum FeedbackType {
  "UX_SURVEY" = "UX_SURVEY",
  "ASK_FEEDBACK_SURVEY" = "ASK_FEEDBACK_SURVEY",
  "WELCOME_BANNER" = "WELCOME_BANNER",
  "SMART_SEARCH_SURVEY" = "SMART_SEARCH_SURVEY",
  "SMART_SEARCH_TOOLTIP" = "SMART_SEARCH_TOOLTIP",
  "EXPIRING_SUBSCRIPTION_BANNER" = "EXPIRING_SUBSCRIPTION_BANNER",
  "CT_HOME_WIDGET" = "CT_HOME_WIDGET",
  "COMPLIANCE_DISCLAIMER" = "COMPLIANCE_DISCLAIMER",
  "CRM_INFO_REQUEST" = "CRM_INFO_REQUEST"
}

export interface Feedback {
  type: FeedbackType;
  data?: ExpiringSubscruptionFeedbackData | WelcomeBannerData | CRMInfoData;
}

export interface ExpiringSubscruptionFeedbackData {
  status: ExpiringSubscriptionStatus;
  expiration: string;
  productId: string;
}

export interface CRMInfoData {
  allowSkip: boolean;
  numberIgnoresLeft: number;
}

export interface WelcomeBannerData {
  type: WelcomeBannerType;
}

export type WelcomeBannerType = "JNJ_SUBSCRIPTION" | "JNJ_NON_SUBSCRIPTION";

export enum ExpiringSubscriptionStatus {
  "EXPIRING" = "EXPIRING",
  "EXPIRED" = "EXPIRED"
}
