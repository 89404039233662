import castArray from "lodash/castArray";
import React, { useEffect } from "react";

/**
 * Detects clicks outside the received targets and executes the callback.
 */
export const useClickOutside = (
  handler: (ev: Event) => void,
  refsCurrent: Array<React.RefObject<HTMLElement | null> | HTMLElement | null>
) => {
  useEffect(() => {
    if (!refsCurrent) {
      return;
    }

    const refsCurrentAsArray = castArray(refsCurrent);

    if (refsCurrentAsArray.length === 0) {
      return;
    }

    const listener = (event: Event) => {
      for (const current of refsCurrentAsArray) {
        const el = current as any;

        if (!el || el?.contains(event.target as Node)) {
          return;
        }
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [handler, refsCurrent]);
};
