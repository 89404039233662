import { Typography } from "@beckersdp/becker-ds";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "../../i18n";

export const ReviewedCopyright: React.FC = () => {
  const { t } = useTranslation(["copyright"]);

  const backEndVersion = window?.__config__?.backEndVersion;
  const configBackEndBuild = window?.__config__?.backEndBuild;
  const configFrontendCommit = window?.__config__?.frontendCommit;

  const backEndBuild = configBackEndBuild ? `BackEnd Build: ${configBackEndBuild}` : "";
  const frontendCommit = configFrontendCommit ? `Frontend Build: ${configFrontendCommit}` : "";

  return (
    <>
      {backEndVersion && (
        <Copyright>
          <Typography.Text level="3" color="primary-inverted">
            {t("copyright:cpe_learning")} <span title={`${backEndBuild} \n ${frontendCommit}`}>{backEndVersion}</span>{" "}
            {t("copyright:rights_reserved")}
          </Typography.Text>
        </Copyright>
      )}
    </>
  );
};

export default ReviewedCopyright;

const Copyright = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 2px;
`;
