import { Course } from "@cpe/models/course";
import { SectionRequest } from "@cpe/models/section";
import * as FetchUtil from "../fetch-util";

export class Courses {
  static sections(): Promise<FetchUtil.HttpResponse<FetchUtil.ErrorResponse & SectionRequest[]>> {
    return FetchUtil.get("/api/v1/sections/courses?fetchAsync=true");
  }
  static recomendations(fetchUrl: string): Promise<FetchUtil.HttpResponse<FetchUtil.ErrorResponse & Course[]>> {
    return FetchUtil.get(fetchUrl);
  }
}
