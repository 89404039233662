import { Alert, Button, getScreenCheckers, Password, Typography } from "@beckersdp/becker-ds";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "../../i18n";
import { UpdatePasswordQaLabels } from "../../page-objects/pages/update-password/qa-labels";
import { availableRoutes } from "../../router/routes";
import { useResetPasswordForm } from "../reset-password/use-reset-password-form";

interface ComponentProps {
  onSubmit: () => void;
}

export const UpdatePasswordForm: FC<ComponentProps> = () => {
  const { t } = useTranslation(["resetPassword"]);
  const theme = useTheme();
  const { isPhone } = getScreenCheckers(theme.breakpoints, window.innerWidth);
  const history = useHistory();
  const {
    values,
    errors,
    touched,
    dirty,
    isValid,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    serverErrors
  } = useResetPasswordForm();
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {isPhone && <Typography.Text level="3">{t("resetPassword:defaultPasswordUpdate")}</Typography.Text>}
        {serverErrors && (
          <StyledAlert data-qa-label={UpdatePasswordQaLabels.formErrors} type="danger">
            {serverErrors}
          </StyledAlert>
        )}
        <Password
          label={t("resetPassword:newPassword")}
          id="newPassword"
          onChange={handleChange}
          onClear={() => setFieldValue("newPassword", "")}
          onBlur={(e: React.SyntheticEvent) => {
            const event = Object.assign({}, e);
            setTimeout(() => handleBlur(event), 250);
          }}
          value={values.newPassword}
          qaLabel={UpdatePasswordQaLabels.newPassword}
          error={touched.newPassword && errors.newPassword ? errors.newPassword : ""}
        />
        <StyledPassword
          label={t("resetPassword:confirmPassword")}
          id="confirmNewPassword"
          onChange={handleChange}
          onBlur={(e: React.SyntheticEvent) => {
            const event = Object.assign({}, e);
            setTimeout(() => handleBlur(event), 250);
          }}
          onClear={() => setFieldValue("confirmNewPassword", "")}
          value={values.confirmNewPassword}
          qaLabel={UpdatePasswordQaLabels.confirmNewPassword}
          error={touched.confirmNewPassword && errors.confirmNewPassword ? errors.confirmNewPassword : ""}
        />
        <div>
          <StyledButton
            data-qa-label={UpdatePasswordQaLabels.submitButton}
            loading={isSubmitting}
            disabled={!(dirty && isValid)}
            type="primary"
            htmlType="submit"
          >
            {t("resetPassword:submit")}
          </StyledButton>
        </div>
      </Form>
      <div>
        <StyledButton
          data-qa-label={UpdatePasswordQaLabels.backToLoginButton}
          onClick={() => history.push(availableRoutes.generalSignIn)}
          icon="arrow-left"
        >
          {t("resetPassword:backToLogin")}
        </StyledButton>
      </div>
    </Container>
  );
};

const StyledPassword = styled(Password)`
  margin-bottom: 2rem;
`;

const Container = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
const Form = styled.form`
  width: 100%;

  ${StyledButton} {
    margin-top: 0.625rem;
  }

  margin-bottom: 1.875rem;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 2.5rem;
`;
