import { AvailableFeaturesFlags } from "@cpe/models/feature-flag";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type FF = { [K in AvailableFeaturesFlags]?: boolean };

interface ActiveFeatureFlagsState {
  activeFeatureFlags: FF;
}

export const initialState: ActiveFeatureFlagsState = {
  activeFeatureFlags: {}
};

export const activeFeatureFlagsSlice = createSlice({
  name: "activeFeatureFlags",
  initialState: initialState,
  reducers: {
    setFeatureFlags: (state, action: PayloadAction<FF>) => {
      state.activeFeatureFlags = { ...state.activeFeatureFlags, ...action.payload };
    }
  }
});

export const { setFeatureFlags } = activeFeatureFlagsSlice.actions;
export default activeFeatureFlagsSlice.reducer;
