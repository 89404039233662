import { Icon, Popover } from "@beckersdp/becker-ds";
import { NavigationElement, NavigationItem } from "@cpe/models/navigation-link";
import kebabCase from "lodash/kebabCase";
import React, { FC, RefObject, useState } from "react";
import styled from "styled-components";
import { useClickOutside } from "../../../../../../../../hooks/use-click-outside";
import { PageQALabels } from "../../../../../../../../page-objects/components/page/qa-labels";
import { useFeatureFlags } from "../../../../../../../feature-flag/use-feature-flags";

interface Props {
  items: NavigationElement;
}

export const HelpLinks: FC<Props> = props => {
  const { items } = props;
  const [isOpen, setOpen] = useState(false);
  const { isFFActive } = useFeatureFlags();
  const [helpElement, setHelpElement] = useState<HTMLElement | null>(null);
  const helpElementAsRefCurrent = helpElement as any as RefObject<HTMLElement>;
  useClickOutside(() => {
    setOpen(false);
  }, [helpElementAsRefCurrent]);
  return (
    <div ref={setHelpElement}>
      <Popover
        style={{ top: "-50px" }}
        placement="bottom"
        visible={isOpen}
        triggerEvents={["click", "focus"]}
        content={
          <OptionsContainer>
            {(items as NavigationItem[]).map(link => (
              <A
                key={link.label}
                href={link.url}
                target={link.external ? "_blank" : ""}
                onClick={() => setOpen(false)}
                data-qa-label={`${kebabCase(link.label)}-link`}
              >
                {link.label}
              </A>
            ))}
          </OptionsContainer>
        }
      >
        <LI role="listitem" tabIndex={0} data-qa-label={PageQALabels.helpDropdown} onClick={() => setOpen(!isOpen)}>
          <CustomIcon name={isFFActive("BECKER_HELP_CENTER_LINK") ? "life-buoy" : "question-circle"} />
        </LI>
      </Popover>
    </div>
  );
};
const OptionsContainer = styled.div`
  display: flex;
  flex-flow: column;
`;
export const A = styled.a`
  box-sizing: border-box;
  padding-top: 4px;
  height: 44px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-family: "Euclid Circular";
  font-weight: 700;
  line-height: 20px;
  color: ${props => props.theme.colors?.brand01.default};
  &.selected {
    color: ${props => props.theme.colors?.brand01.default};
  }
  &:link,
  :visited {
    text-decoration: inherit;
    color: ${props => props.theme.colors?.brand01.default};
  }
  &:hover {
    text-decoration: underline;
  }
`;

const CustomIcon = styled(Icon)`
  height: 31px;
  width: 31px;
  color: ${props => props.theme.colors.brand01[600]};
`;

const LI = styled.div`
  box-sizing: border-box;
  height: 62px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding-top: 12px;
  margin-bottom: 12px;
  gap: 10px;
  color: ${props => props.theme.colors.brand01[700]};

  & :hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
  &&:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.brand01.default};
  }
  &.active {
    color: ${props => props.theme.colors.brand01.default};
  }
`;
