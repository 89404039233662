import React, { FC, useEffect } from "react";
import { Footer } from "../../src/components/footer";
import { useAppDispatch } from "../../src/store";
import { Eula } from "../../src/store/eula/thunks";

export const FooterWrapper: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(Eula());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Footer />;
};
