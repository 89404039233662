import { Typography } from "@beckersdp/becker-ds";
import React, { FC } from "react";
import styled from "styled-components";
import {
  GA_CT_RENEW_SELECT_PLUS_CT,
  GA_CT_TRIAL_UPGRADE_FROM_SELECT_TO_PRIME,
  GA_CT_TRIAL_UPGRADE_ONLY_CT
} from "../../ga/events/compliance/compliance-trial";
import { sendGACustomEvent } from "../../ga/ga";
import { useUser } from "../../hooks";
import { useTranslation } from "../../i18n";
import { UpgradeModalQALabels } from "../../page-objects/components/upgrade-modal/qa-labels";
import { formatedPrice } from "../../utils/price";
import { PromotionCard } from "./components/promotion-card";
import { CardsContainer, ComplianceOnly, Title } from "./styles";
import { PromoModalProps } from "./types";

export const SelectPlusCompliance: FC<PromoModalProps> = props => {
  const { ct, upgrade, crossell } = props;
  const { t } = useTranslation(["context_ctTrial"]);
  const { user } = useUser();
  const trialStatus = user.licenses?.ct?.status || "";
  const prime_upgrade_price = upgrade.price;
  const select_plus_compliance_price = crossell?.price;
  const compliance_tracker_add_on_price = ct.price;

  const handleUpgrade = () => {
    sendGACustomEvent(GA_CT_TRIAL_UPGRADE_FROM_SELECT_TO_PRIME(1, trialStatus, true));
    if (!upgrade.link) {
      return;
    }
    if (upgrade.link?.external) {
      window.open(upgrade.link.url, "_blank");
    } else {
      window.location.href = upgrade.link.url;
    }
  };
  const handleRenewSelectCompliance = () => {
    sendGACustomEvent(GA_CT_RENEW_SELECT_PLUS_CT(1, trialStatus));
    if (!crossell?.link) {
      return;
    }
    if (crossell?.link?.external) {
      window.open(crossell.link.url, "_blank");
    } else {
      window.location.href = crossell.link.url;
    }
  };
  const handleBuyCompliance = () => {
    sendGACustomEvent(GA_CT_TRIAL_UPGRADE_ONLY_CT(1, trialStatus));
    if (!ct.link) {
      return;
    }
    if (ct.link?.external) {
      window.open(ct.link.url, "_blank");
    } else {
      window.location.href = ct.link.url;
    }
  };
  return (
    <>
      <Title level="1" data-qa-label={UpgradeModalQALabels.title}>
        {t("context_ctTrial:crossell.title")}
      </Title>
      <CustomCardContainer>
        <PromotionCard
          title={t("context_ctTrial:prime.name")}
          price={t("context_ctTrial:prime.price", { price: formatedPrice(prime_upgrade_price, 0) })}
          advantages={[
            t("context_ctTrial:prime.advantage_1"),
            t("context_ctTrial:prime.advantage_2"),
            t("context_ctTrial:prime.advantage_3")
          ]}
          action={{ name: t("context_ctTrial:prime.upgrade.action"), onAction: handleUpgrade }}
        />
        <PromotionCard
          title={t("context_ctTrial:select_plus_compliance.name")}
          price={t("context_ctTrial:select_plus_compliance.price", {
            price: formatedPrice(select_plus_compliance_price)
          })}
          type="Alternative"
          advantages={[
            t("context_ctTrial:select_plus_compliance.advantage_1"),
            t("context_ctTrial:select_plus_compliance.advantage_2")
          ]}
          action={{
            name: t("context_ctTrial:select_plus_compliance.upgrade.action"),
            onAction: handleRenewSelectCompliance
          }}
        />
      </CustomCardContainer>
      <ComplianceOnly>
        <Typography.Text level="2">
          {t("context_ctTrial:compliance_only.or")}{" "}
          <Typography.Link onClick={handleBuyCompliance} inline>
            {t("context_ctTrial:compliance_only.link")}
          </Typography.Link>{" "}
          {t("context_ctTrial:compliance_only.price", { price: formatedPrice(compliance_tracker_add_on_price) })}
        </Typography.Text>
      </ComplianceOnly>
    </>
  );
};

const CustomCardContainer = styled(CardsContainer)`
  margin-bottom: 0px;
`;
