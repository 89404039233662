import { Button, Typography } from "@beckersdp/becker-ds";
import kebabCase from "lodash/kebabCase";
import React, { FC } from "react";
import styled from "styled-components";
import { useUser } from "../../../../../../hooks";
import { useAppDispatch } from "../../../../../../hooks/store";
import { useTranslation } from "../../../../../../i18n";
import { clearUser } from "../../../../../../store/user";
import { Avatar } from "../../../../../avatar";

interface Props {
  className?: string;
}

export const AccountSetting: FC<Props> = props => {
  const { className } = props;
  const { t } = useTranslation(["context_app"]);
  const dispatch = useAppDispatch();
  const { user } = useUser();
  const urls = [
    {
      name: t("context_app:settings"),
      action: (ev: React.SyntheticEvent) => {
        ev.preventDefault();
        window.location.href = "/account/profile";
      },
      icon: "settings"
    },
    {
      name: t("context_app:logout"),
      action: (ev: React.SyntheticEvent) => {
        ev.preventDefault();
        window.sessionStorage.clear();
        dispatch(clearUser());
        window.location.href = "/logout";
      },
      icon: "log-out"
    }
  ];
  return (
    <Container className={className}>
      <Avatar size="large" name={`${user.firstName} ${user.lastName}`} />
      <UserAccount>
        <Typography.Text level="1" weight="bold">
          {`${user.firstName ? user.firstName : ""} ${user.lastName ? user.lastName : ""}`}
        </Typography.Text>
        <Typography.Text level="2" weight="regular">
          {user.username || user.email}
        </Typography.Text>
        <ActionsContainer>
          <Button
            size="small"
            type="outline"
            onClick={urls[0].action}
            icon={urls[0].icon}
            data-qa-label={`${kebabCase(urls[0].name)}-link`}
          >
            {urls[0].name}
          </Button>
          <Button
            size="small"
            type="outline"
            onClick={urls[1].action}
            icon={urls[1].icon}
            data-qa-label={`${kebabCase(urls[1].name)}-link`}
          >
            {urls[1].name}
          </Button>
        </ActionsContainer>
      </UserAccount>
    </Container>
  );
};

const UserAccount = styled.div`
  padding-top: 16px;
  display: flex;
  flex-flow: column;
`;
const Container = styled.div`
  padding: 0 0 0 0;
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;

const ActionsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 24px;
`;
