import { ErrorResponse } from "@cpe/services/fetch-util";
import { UserSettingsService, UserSettingsType } from "@cpe/services/user-settings";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const UserSettingsThunk = createAsyncThunk<
  (ErrorResponse & UserSettingsType) | undefined,
  string | undefined,
  any
>("user/settings/get", async () => (await UserSettingsService.get()).parsedBody);

export const UserSettingsUpdateThunk = createAsyncThunk<
  (ErrorResponse & UserSettingsType) | undefined,
  UserSettingsType,
  any
>("user/settings/update", async (keys: UserSettingsType) => (await UserSettingsService.update({ ...keys })).parsedBody);
