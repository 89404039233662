import { Button, Icon, Typography } from "@beckersdp/becker-ds";
import { ButtonProps } from "@beckersdp/becker-ds/dist/button/types";
import { Course } from "@cpe/models/course";
import React, { FC } from "react";
import styled, { css } from "styled-components";
import { useCart } from "../../../../hooks";
import { useDeviceBreakpoint } from "../../../../hooks/use-device-breakpoint";
import { useTranslation } from "../../../../i18n";
import { courseCardQALabels } from "../../../../page-objects/components/course-card/qa-labels";
import { media } from "../../../../utils/media-queries";

interface Props {
  size: ButtonProps["size"];
  className?: string;
  addToCart: (event: React.SyntheticEvent<Element, Event>) => Promise<void>;
  addingToCart: boolean;
  course: Course;
  extendedLabels?: boolean;
  shortenedLabels?: boolean;
}

export const _BuyButton: FC<Props> = props => {
  const { size, className, addToCart, addingToCart, course, extendedLabels, shortenedLabels } = props;
  const { t } = useTranslation(["courseCard"]);
  const cart = useCart();
  const { isDesktop } = useDeviceBreakpoint();
  const isInCart = Boolean(cart?.items?.find(item => item.productId === course.productId));

  const label = extendedLabels
    ? t("courseCard:card_buy_cart_with_buy_label", {
        price: parseFloat(course.purchaseInfo?.price.toString() || "0").toFixed(2)
      })
    : t("courseCard:card_buy_cart", { price: parseFloat(course.purchaseInfo?.price.toString() || "0").toFixed(2) });

  const renderAddedToCartLabel = () => {
    if (extendedLabels || (shortenedLabels && isDesktop)) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon width="0.9375rem" name="checkbox" />
          <Text weight="bold" level="2">
            {shortenedLabels ? t("courseCard:added_to_cart_shortened") : t("courseCard:added_to_cart")}
          </Text>
        </div>
      );
    }

    return <Icon width="0.9375rem" name="checkbox" />;
  };

  return (
    <StyledButton
      $extendedLabels={extendedLabels}
      $shortenedLabels={shortenedLabels}
      title={t("courseCard:buy_title")}
      aria-label={t("courseCard:buy_title")}
      qaLabel={courseCardQALabels.addToCartButton}
      type="onboarding"
      size={size}
      loading={addingToCart}
      onClick={addToCart}
      icon={!isInCart ? "cart" : undefined}
      disabled={isInCart && !addingToCart}
      className={className}
    >
      {!isInCart ? label : renderAddedToCartLabel()}
    </StyledButton>
  );
};

const ExtendedIconStyle = css`
  svg {
    width: 24px;
    height: 24px;

    ${media.desktopSD.min} {
      width: 18px;
      height: 18px;
    }
  }
`;
const ShortenedIconStyle = css`
  svg {
    width: 24px;
    height: 24px;
  }
`;

const StyledButton = styled(Button)<{ $extendedLabels?: boolean; $shortenedLabels?: boolean }>`
  svg {
    width: 18px;
    height: 18px;
  }

  ${props => props.$extendedLabels && ExtendedIconStyle}
  ${props => props.$shortenedLabels && ShortenedIconStyle}
`;

const Text = styled(Typography.Text)`
  padding-left: 0.5rem; //8px
  color: ${props => props.theme.colors.brand01[500]};
`;

export const BuyButton = styled(_BuyButton)`
  min-width: 5.625rem; //90px
  svg {
    flex-shrink: 0;
    width: 18px;
    height: 18px;

    ${media.desktopSD.min} {
      width: 24px;
      height: 24px;
    }
  }
  div {
    display: flex;
    flex-grow: 0;
  }
  justify-content: center;
`;
