import React, { FC } from "react";
import { UpsellButton, UpsellButtonProps } from "../../src/components/upsell-button";
import { GA_CT_TRIAL_UPGRADE } from "../../src/ga/events/compliance/compliance-trial";
import { sendGACustomEvent } from "../../src/ga/ga";
import { useUser } from "../../src/hooks";

export const UpgradeButton: FC<UpsellButtonProps> = props => {
  const { user } = useUser();
  const trialStatus = user?.licenses?.ct?.status || "";
  return (
    <UpsellButton
      {...props}
      onClick={() => {
        sendGACustomEvent(GA_CT_TRIAL_UPGRADE(0, trialStatus));
      }}
    />
  );
};
