import React, { FC, lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useFeatureFlags } from "../components/feature-flag/use-feature-flags";
import { sendGaPageView } from "../ga/ga";
import RecoverPassword from "../pages/recover-password";
import UpdateDefaultPassword from "../pages/update-password";
import { PrivateRoute } from "./private-route";
import { useStorageContextCleaner } from "./use-storage-context-cleaner";

const Home = lazy(() => import("../pages/home"));
const GeneralSignIn = lazy(() => import("../pages/sign-in/general"));
const TenantSignIn = lazy(() => import("../pages/sign-in/tenant"));
const ResetPassword = lazy(() => import("../pages/reset-password"));
const Cart = lazy(() => import("../pages/cart"));
const Courses = lazy(() => import("../pages/courses"));
const GetCredits = lazy(() => import("../pages/get-credits"));
const LicenseAdd = lazy(() => import("../pages/accreditation-license/add"));
const AccreditationLicenseEdit = lazy(() => import("../pages/accreditation-license/edit"));
const ComplianceStudent = lazy(() => import("../pages/compliance/student"));
const ComplianceAdmin = lazy(() => import("../pages/compliance/user-admin"));
const ComplianceUsers = lazy(() => import("../pages/compliance-users"));
const CertificatesAddBulk = lazy(() => import("../pages/certificates-bulk/add"));
const CertificatesEditBulk = lazy(() => import("../pages/certificates-bulk/edit"));
const ExternalPrograms = lazy(() => import("../pages/external-programs"));
const CertificatesAdd = lazy(() => import("../pages/certificates/add"));
const CertificatesEdit = lazy(() => import("../pages/certificates/edit"));
const LicensesAndJurisdictions = lazy(() => import("../pages/licenses-jurisdictions"));
const LicensesAndJurisdictionsAddBulk = lazy(() => import("../pages/license-and-jurisdictions-bulk/add"));
const LicensesAndJurisdictionsEditBulk = lazy(() => import("../pages/license-and-jurisdictions-bulk/edit"));
const CourseDetails = lazy(() => import("../pages/course-details"));
const CoursePlayerPage = lazy(() => import("../pages/course-player"));
const FinalExamPage = lazy(() => import("../pages/final-exam"));
const CourseReviewSuccess = lazy(() => import("../pages/review-success"));

export const Routes: FC = () => {
  const { pathname, search } = useLocation();
  const { isFFActive, isLoading } = useFeatureFlags();
  useStorageContextCleaner(pathname);

  useEffect(() => {
    sendGaPageView(pathname);
  }, [pathname]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {process.env.NODE_ENV === "development" && (
          <Redirect exact from={availableRoutes.courseDetailsLegacy} to={availableRoutes.courseDetails} />
        )}
        {isFFActive("REORG_ADMIN_NAVIGATION") && (
          <Redirect exact from={availableRoutes.userCompliance} to={availableRoutes.newUserCompliance} />
        )}
        {isFFActive("REORG_ADMIN_NAVIGATION") && (
          <Redirect
            from={availableRoutes.userAddAccreditationLicense}
            to={`${availableRoutes.newUserAddAccreditationLicense}${search}`}
          />
        )}
        {isFFActive("REORG_ADMIN_NAVIGATION") && (
          <Redirect exact from={availableRoutes.complianceUsers} to={availableRoutes.newComplianceUsers} />
        )}
        {isFFActive("REORG_ADMIN_NAVIGATION") && (
          <Redirect
            exact
            from={availableRoutes.userEditAccreditationLicense}
            to={`${availableRoutes.newUserEditAccreditationLicense}${search}`}
          />
        )}
        {isFFActive("REORG_ADMIN_NAVIGATION") && (
          <Redirect
            exact
            from={availableRoutes.userAddExternalCertificates}
            to={`${availableRoutes.newUserAddExternalCertificates}${search}`}
          />
        )}
        {isFFActive("REORG_ADMIN_NAVIGATION") && (
          <Redirect
            exact
            from={availableRoutes.userEditExternalCertificates}
            to={`${availableRoutes.newUserEditExternalCertificates}${search}`}
          />
        )}
        {isFFActive("REORG_ADMIN_NAVIGATION") && (
          <Redirect
            exact
            from={availableRoutes.certificatesExternalAddBulk}
            to={`${availableRoutes.newCertificatesExternalAddBulk}${search}`}
          />
        )}
        {isFFActive("REORG_ADMIN_NAVIGATION") && (
          <Redirect
            exact
            from={availableRoutes.externalPrograms}
            to={`${availableRoutes.newExternalPrograms}${search}`}
          />
        )}
        <PrivateRoute exact path={availableRoutes.default} component={Home} pageId="HOME" />
        <Route exact path={availableRoutes.generalSignIn} component={GeneralSignIn} />
        <Route exact path={availableRoutes.tenantSignIn} component={TenantSignIn} />
        <Route exact path={availableRoutes.recoverPassword} component={RecoverPassword} />
        <Route exact path={availableRoutes.resetPassword} component={ResetPassword} />
        <Route exact path={availableRoutes.updateDefaultPassword} component={UpdateDefaultPassword} />
        <PrivateRoute exact path={availableRoutes.cart} component={Cart} pageId="CART" />
        <PrivateRoute path={[availableRoutes.courseDetails]} component={CourseDetails} exact pageId="COURSE_DETAILS" />
        <PrivateRoute
          path={[availableRoutes.coursePlayer]}
          component={CoursePlayerPage}
          exact
          pageId="COURSE_PLAYER"
          featureFlag="NEW_COURSE_PLAYER_REACT"
        />
        <PrivateRoute
          path={[availableRoutes.finalExam]}
          component={FinalExamPage}
          exact
          pageId="FINAL_EXAM_PLAYER"
          featureFlag="NEW_COURSE_PLAYER_REACT"
        />
        <PrivateRoute
          path={[availableRoutes.courseReviewSuccess]}
          component={CourseReviewSuccess}
          exact
          pageId="COURSE_REVIEW_SUCCESS"
          featureFlag="NEW_COURSE_PLAYER_REACT"
        />
        <PrivateRoute
          path={availableRoutes.mySearch}
          exact
          pageId="COURSES"
          component={() => <Redirect to={"/courses/all"} />}
        />
        <PrivateRoute
          path={availableRoutes.newCourses}
          exact
          pageId="COURSES"
          component={() => <Redirect to={"/courses/all?custom=NEW"} />}
        />
        <PrivateRoute
          path={availableRoutes.recentlyPurchasedCourses}
          exact
          pageId="COURSES"
          component={() => <Redirect to={"/courses/all?custom=RECENTLY_PURCHASED"} />}
        />
        <PrivateRoute
          path={availableRoutes.learningPathsCourses}
          exact
          pageId="COURSES"
          component={() => <Redirect to={"/courses/all?filter.format=learningpath"} />}
        />
        <PrivateRoute
          path={availableRoutes.primeCourses}
          exact
          pageId="COURSES"
          component={() => <Redirect to={"/courses/all?subscription=PRIME"} />}
        />
        <PrivateRoute
          path={availableRoutes.primeExclusiveCourses}
          exact
          pageId="COURSES"
          component={() => <Redirect to={"/courses/all?subscription=PRIME_EXCLUSIVE"} />}
        />
        <PrivateRoute
          path={availableRoutes.selectCourses}
          exact
          pageId="COURSES"
          component={() => <Redirect to={"/courses/all?subscription=SELECT"} />}
        />
        <PrivateRoute
          path={availableRoutes.selectExclusiveCourses}
          exact
          pageId="COURSES"
          component={() => <Redirect to={"/courses/all?subscription=SELECT_EXCLUSIVE"} />}
        />
        <PrivateRoute
          path={availableRoutes.essentialsCourses}
          exact
          pageId="COURSES"
          component={() => <Redirect to={"/courses/all?subscription=ESSENTIALS"} />}
        />

        <PrivateRoute path={availableRoutes.coursesTab} component={Courses} pageId="COURSES" />
        <PrivateRoute
          path={availableRoutes.courses}
          exact
          pageId="COURSES"
          component={() => <Redirect to={"/courses/mycourses"} />}
        />
        <PrivateRoute path={availableRoutes.getCredits} component={GetCredits} exact pageId="GET_CREDITS" />

        <PrivateRoute
          path={[availableRoutes.accreditationLicenseAdd, availableRoutes.studentComplianceAddAccreditationLicense]}
          component={LicenseAdd}
          exact
          pageId="ADD_COMPLIANCE_LICENSE"
        />
        <PrivateRoute
          path={[availableRoutes.accreditationLicenseEdit, availableRoutes.studentComplianceEditAccreditationLicense]}
          component={AccreditationLicenseEdit}
          exact
          pageId="EDIT_COMPLIANCE_LICENSE"
        />
        <PrivateRoute
          path={[availableRoutes.newUserEditAccreditationLicense, availableRoutes.userEditAccreditationLicense]}
          component={AccreditationLicenseEdit}
          exact
          pageId="USERS_EDIT_COMPLIANCE_LICENSE"
        />
        <PrivateRoute
          path={[availableRoutes.newUserAddAccreditationLicense, availableRoutes.userAddAccreditationLicense]}
          component={LicenseAdd}
          exact
          pageId="USERS_ADD_COMPLIANCE_LICENSE"
        />
        <PrivateRoute
          path={[availableRoutes.newUserAddExternalCertificates, availableRoutes.userAddExternalCertificates]}
          component={CertificatesAdd}
          exact
          pageId="USERS_ADD_EXTERNAL_CERTIFICATE"
        />
        <PrivateRoute
          path={[availableRoutes.newUserCompliance, availableRoutes.newUserCompliance]}
          component={ComplianceAdmin}
          exact
          pageId="USERS_COMPLIANCE"
        />
        <PrivateRoute
          path={[availableRoutes.newComplianceUsers, availableRoutes.newComplianceUsers]}
          exact
          component={ComplianceUsers}
          pageId="USERS_COMPLIANCE_LIST"
        />

        <PrivateRoute
          path={[
            availableRoutes.certificatesExternalAdd,
            availableRoutes.studentComplianceAddExternalCertificate,
            availableRoutes.studentCertificatesAddExternalCertificate
          ]}
          exact
          component={CertificatesAdd}
          pageId="ADD_EXTERNAL_CERTIFICATE"
        />

        <PrivateRoute
          path={[
            availableRoutes.certificatesExternalEdit,
            availableRoutes.studentComplianceEditExternalCertificate,
            availableRoutes.studentCertificatesEditExternalCertificate
          ]}
          exact
          component={CertificatesEdit}
          pageId="EDIT_EXTERNAL_CERTIFICATE"
        />

        <PrivateRoute path={availableRoutes.compliance} component={ComplianceStudent} pageId="COMPLIANCE" />

        <PrivateRoute
          path={[availableRoutes.newExternalPrograms, availableRoutes.externalPrograms]}
          exact
          component={ExternalPrograms}
          pageId="LANDING_PAGE_EXTERNAL_PROGRAMS"
        />

        <PrivateRoute
          path={[
            availableRoutes.adminComplianceExternalProgramAdd,
            availableRoutes.newCertificatesExternalAddBulk,
            availableRoutes.certificatesExternalAddBulk
          ]}
          exact
          component={CertificatesAddBulk}
          pageId="ADD_EXTERNAL_CERTIFICATE_BULK"
        />

        <PrivateRoute
          path={[availableRoutes.newCertificatesExternalEditBulk, availableRoutes.certificatesExternalEditBulk]}
          component={CertificatesEditBulk}
          exact
          pageId="EDIT_EXTERNAL_CERTIFICATE_BULK"
        />

        <PrivateRoute
          path={[availableRoutes.adminLicensesAndJurisdictions]}
          component={LicensesAndJurisdictions}
          exact
          pageId="BULK_LICENSES_JURISDICTIONS"
          featureFlag={"BULK_LICENSES_JURISDICTIONS"}
        />
        <PrivateRoute
          path={[availableRoutes.adminLicensesAndJurisdictionsAddBulk]}
          component={LicensesAndJurisdictionsAddBulk}
          exact
          pageId="BULK_LICENSES_JURISDICTIONS"
          featureFlag={"BULK_LICENSES_JURISDICTIONS"}
        />
        <PrivateRoute
          path={[availableRoutes.adminLicensesAndJurisdictionsEditBulk]}
          component={LicensesAndJurisdictionsEditBulk}
          exact
          pageId="BULK_LICENSES_JURISDICTIONS_EDIT"
          featureFlag={"BULK_LICENSES_JURISDICTIONS"}
        />

        <Route
          path="*"
          component={(props: any) => {
            if (isLoading === false) {
              window.location.assign(props.location.pathname);
            }

            return null;
          }}
        />
      </Switch>
    </Suspense>
  );
};

export const availableRoutes = {
  accreditationLicenseAdd: "/account/profile/accreditation-license/add",
  accreditationLicenseEdit: "/account/profile/accreditation-license/:licenseId/edit",
  // To Deprecate
  complianceUsers: "/compliance/users",
  userCompliance: "/compliance/users/:userId",
  userAddAccreditationLicense: "/compliance/users/:userId/accreditation-license/add",
  userEditAccreditationLicense: "/compliance/users/:userId/accreditation-license/:licenseId/edit",
  userAddExternalCertificates: "/compliance/users/:userId/certificates/external/add",
  userEditExternalCertificates: "/compliance/users/:userId/certificates/external/:certificateId/edit",
  certificatesExternalAddBulk: "/certificates/external/add-multiple",
  certificatesExternalEditBulk: "/certificates/external/add-multiple/:programId/edit",
  externalPrograms: "/certificates/external/admin",
  certificatesExternalAdd: "/certificates/external/add",
  certificatesExternalEdit: "/certificates/external/:certificateId/edit",
  certificatesLegacy: "/myAccomplishments",
  //COMMON ROUTES
  default: "/",
  profile: "/account/profile",
  //STUDENTS ROUTES
  //  Courses Routes
  mySearch: "/mySearch",
  courses: "/courses",
  coursesTab: "/courses/:tab",
  newCourses: "/courses/new",
  recentlyPurchasedCourses: "/courses/recentlypurchased",
  myCourses: "/courses/mycourses",
  learningPathsCourses: "/courses/learningpaths",
  providerCourses: "/courses/provider",
  primeCourses: "/courses/primecourses",
  primeExclusiveCourses: "/courses/primeexclusivecourses",
  selectCourses: "/courses/selectcourses",
  selectExclusiveCourses: "/courses/selectexclusivecourses",
  essentialsCourses: "/courses/essentialscourses",
  courseDetails: "/courses/:productId/detail",
  coursePlayer: "/courses/:productId/player/:courseMediaId",
  courseReviewSuccess: "/courses/:productId/review-success",
  finalExam: "/courses/:productId/player/:courseMediaId/final-exam",
  //  Calendar Routes
  calendar: "/calendar/:viewType/:tab",
  //  Cart Routes
  cart: "/cart",
  //  Compliance Routes
  myCompliance: "/myCompliance",
  compliance: "/compliance",
  getCredits: "/compliance/get-credits/:licenseId/:tab",
  studentComplianceAddExternalCertificate: "/compliance/certificates/external/add",
  studentComplianceEditExternalCertificate: "/compliance/certificates/external/:certificateId/edit",
  studentComplianceAddAccreditationLicense: "/compliance/accreditation-license/add",
  studentComplianceEditAccreditationLicense: "/compliance/accreditation-license/:licenseId/edit",
  // Certificates Routes
  studentCertificatesAddExternalCertificate: "/certificates/external/add",
  studentCertificatesEditExternalCertificate: "/certificates/external/:certificateId/edit",
  // Profile
  studentProfileAccreditationLicenseAdd: "/account/profile/accreditation-license/add",
  studentProfileAccreditationLicenseEdit: "/account/profile/accreditation-license/:licenseId/edit",
  //ADMIN ROUTES
  users: "/users",
  // Compliance Routes
  newComplianceUsers: "/admin/compliance/users",
  newUserCompliance: "/admin/compliance/users/:userId",
  newUserAddAccreditationLicense: "/admin/compliance/users/:userId/accreditation-license/add",
  newUserEditAccreditationLicense: "/admin/compliance/users/:userId/accreditation-license/:licenseId/edit",
  newUserAddExternalCertificates: "/admin/compliance/users/:userId/certificates/external/add",
  newUserEditExternalCertificates: "/admin/compliance/users/:userId/certificates/external/:certificateId/edit",
  adminComplianceExternalProgramAdd: "/admin/compliance/users/programs/external/add-multiple",
  adminLicensesAndJurisdictions: "/admin/compliance/licenses",
  adminLicensesAndJurisdictionsAddBulk: "/admin/compliance/licenses/add-bulk/:step",
  adminLicensesAndJurisdictionsEditBulk: "/admin/compliance/licenses/bulk/:regulatorLicenseId/edit/:step",
  // External Programs
  newExternalPrograms: "/admin/programs/external",
  newCertificatesExternalAddBulk: "/admin/programs/external/add-multiple",
  newCertificatesExternalEditBulk: "/admin/programs/external/:programId/edit",
  //PUBLIC ROUTES
  generalSignIn: "/signin",
  tenantSignIn: "/signin/:tenant_ref_key",
  recoverPassword: "/forgotPassword",
  resetPassword: "/resetPassword", // changing this will need to change it on the recover password email
  updateDefaultPassword: "/updateDefaultPassword",
  // LEGACY
  courseDetailsLegacy: "/course/:productId/detail"
};

export interface TenantSignInParams {
  tenant_ref_key: string;
}

export interface getCreditsParams {
  licenseId: string;
  tab: "all" | "annuals";
}

export interface SignInParams {
  redirectTo?: string;
}

export interface AccreditationLicenseEditParams {
  licenseId: string;
  userId?: string;
}

export interface CertificateEditParams {
  certificateId: string;
  userId?: string;
}

export interface UserComplianceParams {
  userId: string;
}

export interface UsersExternalComplianceCertifiacte {
  userId: string;
}

export interface CertificatesExternalEditBulk {
  programId: string;
}

export interface LicensesAndJurisdictionsEditBulkParams {
  regulatorLicenseId: string;
  step: string;
}
export interface CoursePlayerParams {
  productId: string;
  courseMediaId: string;
}
