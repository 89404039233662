import React from "react";
import ReactDOM from "react-dom/client";
import { StyleSheetManager } from "styled-components";
import { PageId } from "../src/router/types";
import { CPEWrapper } from "./cpe-wrapper";
import { UpgradeButton } from "./upgrade-button/index";

type Size = "small" | "medium" | "large";

class CPEBuyButton extends HTMLElement {
  private readonly styleHost: HTMLElement;
  private readonly mountPoint: HTMLElement;
  constructor() {
    super();
    this.mountPoint = document.createElement("div");
  }
  connectedCallback() {
    ReactDOM.createRoot(this).render(
      <StyleSheetManager disableCSSOMInjection={true}>
        <CPEWrapper pageId={(this.getAttribute("page") || "USER_PROFILE") as PageId} withAuth>
          <UpgradeButton
            forceUpgradeModal={Boolean(this.getAttribute("forceUpgradeModal"))}
            size={(this.getAttribute("size") || "small") as Size}
          >
            {this.innerText}
          </UpgradeButton>
        </CPEWrapper>
      </StyleSheetManager>
    );
  }
  disconnectedCallback() {
    this.shadowRoot?.removeChild(this.mountPoint);
    this.shadowRoot?.removeChild(this.styleHost);
  }
}
customElements.define("cpe-upgrade-button", CPEBuyButton);
