import { Button } from "@beckersdp/becker-ds";
import { SuccessCodeRegex } from "@cpe/services/fetch-util";
import { CartService } from "@cpe/services/store/cart";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useCart } from "../../../hooks";
import { useTranslation } from "../../../i18n";
import { MiniCartQALabels } from "../../../page-objects/components/mini-cart/qa-labels";
import { availableRoutes } from "../../../router/routes";
import { useAppDispatch } from "../../../store";
import { setMiniCartOpenStatus } from "../../../store/cart";
import MiniCartItem from "./mini-cart-item";
import MiniCartTopRow from "./mini-cart-top-row";

const MiniCartWithItems: React.FC = () => {
  const cart = useCart();
  const { t } = useTranslation(["cart"]);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleViewCart = useCallback(() => {
    // history.push(availableRoutes.cart); //TODO: uncomment when student migration is finished
    window.location.href = availableRoutes.cart;
    dispatch(setMiniCartOpenStatus());
  }, [dispatch]);
  const showErrorModal = () =>
    /* Modal.danger({
    title: "couldn't perform the action",
    content: "Please check your internet connection and try again"
  }) */ "";

  const checkout = async (event: React.SyntheticEvent) => {
    const productIds: string[] = [];
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    cart.items?.map(item => productIds.push(item?.productId));
    const response = await CartService.checkout(productIds);
    const redirectUrl = response.parsedBody?.redirectUrl;
    if (response?.status.toString().match(SuccessCodeRegex) && redirectUrl) {
      window.open(redirectUrl, "cpe_sf_cart");
    } else {
      showErrorModal();
    }
    setLoading(false);
  };

  return (
    <>
      <MiniCartTopRow title={t("cart:added_to_cart")} />
      <ItemsList>
        {cart.items.map((item, index) => (
          <MiniCartItem key={index} {...item} />
        ))}
      </ItemsList>
      <MiniCartActions>
        <Button onClick={handleViewCart} type="secondary" qaLabel={MiniCartQALabels.viewCartButton}>
          {t("cart:view_cart_button")}
        </Button>
        <Button type="primary" loading={isLoading} onClick={checkout} qaLabel={MiniCartQALabels.checkoutButton}>
          {t("cart:checkout_button")}
        </Button>
      </MiniCartActions>
    </>
  );
};

const ItemsList = styled.ol`
  margin: 1rem 0 0; /* 16px 0 0 */
  overflow-y: auto;
  padding: 0;
`;

const MiniCartActions = styled.div`
  display: flex;
  padding-top: 1rem; /* 16px */
  ${Button} {
    flex: 1;

    &:nth-child(1) {
      margin-right: 0.5rem; /* 8px */
    }

    &:nth-child(2) {
      margin-left: 0.5rem; /* 8px */
    }
  }
`;

export default MiniCartWithItems;
