import { FloatingElement, ReferenceElement, autoUpdate } from "@floating-ui/react";
import { useEffect } from "react";

interface Props {
  reference: ReferenceElement | null;
  floating: FloatingElement | null;
  update: () => void;
}

export const useFloatingPositionUpdate = (props: Props) => {
  useEffect(() => {
    if (props.reference && props.floating) {
      const cleanup = autoUpdate(props.reference, props.floating, props.update);
      return cleanup;
    }
    return;
  }, [props.reference, props.floating, props.update]);
};
