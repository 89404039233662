import { Icon, Typography } from "@beckersdp/becker-ds";
import { NavigationItem } from "@cpe/models/navigation-link";
import kebabCase from "lodash/kebabCase";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useComplianceSectionContext } from "../../../../../../../../../../pages/home/components/compliance-section/compliance-section-provider";
import { availableRoutes } from "../../../../../../../../../../router/routes";
import useComplianceDisclaimerContext from "../../../../../../../../../compliance-legal-disclaimer/compliance-disclaimer-provider";
interface Props {
  item: NavigationItem;
}
export const SubMenuLink: FC<Props> = props => {
  const { item } = props;
  const { shouldShowComplianceDisclaimer, openComplianceDisclaimer } = useComplianceDisclaimerContext();
  const complianceCredits = useComplianceSectionContext();
  const { pathname } = useLocation();
  const end = false;
  const endSlashPosition = item.url !== "/" && item.url.endsWith("/") ? item.url.length - 1 : item.url.length;
  const isActive =
    pathname === item.url.split("?")[0] ||
    (!end && pathname.startsWith(item.url) && pathname.charAt(endSlashPosition) === "/");
  const isComplianceLink = item.url === availableRoutes.compliance;
  const hasLicenses = complianceCredits?.complianceCredits && complianceCredits?.complianceCredits?.length > 0;

  return (
    <LI
      role="list-item"
      className={`${isActive ? "active" : ""}`}
      href={item.url}
      target={item.external ? "_blank" : ""}
      data-qa-label={`${kebabCase(item.label)}-link`}
      onClick={event => {
        if (isComplianceLink && hasLicenses && shouldShowComplianceDisclaimer()) {
          event.preventDefault();
          openComplianceDisclaimer();
          return;
        }
      }}
    >
      <Typography.Text level="2" weight="medium">
        {item.label}
      </Typography.Text>
      <CustomIcon name="chevron-right" />
    </LI>
  );
};

const CustomIcon = styled(Icon)`
  height: 10px;
  color: ${props => props.theme.colors.brand01[500]};
`;

const LI = styled.a`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  border-radius: 4px;
  height: 44px;
  padding: 8px;
  justify-content: space-between;

  & :hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
  &&:hover {
    background-color: ${props => props.theme.colors.info[100]};
    ${CustomIcon} {
      color: ${props => props.theme.colors.brand01.default};
    }
  }
`;
