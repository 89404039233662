import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useNavigation } from "../../../../../../hooks";
import { PageQALabels } from "../../../../../../page-objects/components/page/qa-labels";
import { useFeatureFlags } from "../../../../../feature-flag/use-feature-flags";
import { ImpersonateBanner } from "../../../../../impersonate-banner";
import { AccountNavigation } from "./account-navigation";
import { OverflowMenu } from "./overflow-menu";
// eslint-disable-next-line
const BeckerLogo = require("../../../../../../assets/logo.svg");

export const DesktopHeader: FC = () => {
  const { navigation } = useNavigation();
  const { isFFActive } = useFeatureFlags();

  return (
    <StyledHeader data-qa-label={PageQALabels.header} $sticky={isFFActive("UI_FOR_IMPERSONATING_ACCOUNT")}>
      <ImpersonateBanner />
      <Nav data-qa-label={PageQALabels.primaryNavigation}>
        <StyledLink to="/">
          <StyledLogo />
        </StyledLink>
        <StyledOverflow role="list" navigationLinks={navigation} overflowcomponentsize={99} />
        <AccountNavigation />
      </Nav>
    </StyledHeader>
  );
};

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const StyledOverflow = styled(OverflowMenu)`
  width: 100%;
  height: 70px;
  min-width: 0%;
  max-width: 100%;
  display: flex;
  align-items: flex-end;
`;

const StyledHeader = styled.header<{ $sticky: boolean }>`
  position: ${props => (props.$sticky ? "sticky" : "fixed")};
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-color: ${props => props.theme.colors?.white};
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
  z-index: 1000;
`;

const Nav = styled.nav`
  max-width: 1170px;
  margin: auto;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: stretch;
  padding: 0 40px;
  margin: 0 auto;
  height: 70px;
`;

const StyledLogo = styled(BeckerLogo)`
  max-height: 100%;
  max-width: 153px;
  height: 35.02px;
  padding: 1rem 1rem 1rem 0;
  flex-shrink: 0;
`;
