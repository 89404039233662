import { Course } from "@cpe/models/course";
import { CoursesService } from "@cpe/services/courses";
import { SuccessCodeRegex } from "@cpe/services/fetch-util";
import { CartService } from "@cpe/services/store/cart";
import { useState } from "react";
import {
  GA_PLAYER_ENTRANCE,
  GaPlayerEntranceProps,
  UA_GA_PLAYER_ENTRANCE,
  UA_GaPlayerEntranceProps
} from "../../ga/events/player-entrance";
import { UA_sendGACustomEvent, isGa4, sendGACustomEvent } from "../../ga/ga";
import { useUserSettings } from "../../hooks";
import { useAppDispatch } from "../../store";
import { Cart, CartAdd } from "../../store/cart/thunks";
import { hasAction, hasAnotherCompletion, isOnDemand, isWebcast } from "./helpers";

export function useCourseCard(
  course: Course,
  onRegistry: () => void,
  onUnregistry?: (() => void) | undefined,
  gaOptions?: GaPlayerEntranceProps,
  UA_gaOptions?: UA_GaPlayerEntranceProps
) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const [creditsAlreadyEarned, setCreditsAlreadyEarned] = useState(false);
  const { keys } = useUserSettings();

  const coursesToNotShowCreditsAlreadyEarned = ["FLASHCOURSE"];
  const shouldShowCreditsAlreadyEarned =
    !coursesToNotShowCreditsAlreadyEarned.includes(course?.format) &&
    course?.completion &&
    ((course?.status === "COMPLETED" && isOnDemand(course) && hasAction(course, "LAUNCH")) ||
      (course?.status === "INCOMPLETE" && isOnDemand(course) && hasAction(course, "LAUNCH")) ||
      (hasAction(course, "LAUNCH") && course?.status === "ENROLLED"));

  const shouldShowCreditsAlreadyEarnedAggregated = shouldShowCreditsAlreadyEarned && hasAnotherCompletion(course);

  const shouldShowCreditsAlreadyEarnedNotAggregated =
    shouldShowCreditsAlreadyEarned &&
    course.completion?.productId === course?.productId &&
    (keys.DONT_WARN_CREDITS_ALREADY_EARNED?.toLowerCase() === "false" || !keys.DONT_WARN_CREDITS_ALREADY_EARNED);

  const showErrorModal = () =>
    /* Modal.danger({
      title: "couldn't perform the action",
      content: "Please check your internet connection and try again"
    }) */ "";

  const hideCreditsAlreadyEarnDialog = () => {
    setCreditsAlreadyEarned(false);
    return true;
  };

  const register = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    const response = await CoursesService.register(course.productId);
    if (response?.status.toString().match(SuccessCodeRegex)) {
      onRegistry && (await onRegistry());
    } else {
      showErrorModal();
    }
    setLoading(false);
  };
  const unregister = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    const response = await CoursesService.unregister(course.productId);
    if (response?.status.toString().match(SuccessCodeRegex)) {
      onUnregistry && (await onUnregistry());
    } else {
      showErrorModal();
    }
    setLoading(false);
  };
  const buy = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    const response = await CartService.add([course.productId]);
    const redirectUrl = response.parsedBody?.redirectUrl;
    if (response?.status.toString().match(SuccessCodeRegex) && redirectUrl) {
      window.open(redirectUrl, "cpe_sf_cart");
    } else {
      showErrorModal();
    }
    setLoading(false);
  };
  const addToCart = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setAddingToCart(true);
    const buildCartItem = {
      productId: course.productId,
      name: course.name,
      price: course.purchaseInfo?.price
    };
    await dispatch(CartAdd(buildCartItem));
    await dispatch(Cart());
    setAddingToCart(false);
  };

  const launch = async (event: React.SyntheticEvent) => {
    event?.preventDefault();
    event?.stopPropagation();

    if (isGa4 && gaOptions) {
      sendGACustomEvent(GA_PLAYER_ENTRANCE({ ...gaOptions }));
    }
    if (!isGa4 && UA_gaOptions) {
      UA_sendGACustomEvent(UA_GA_PLAYER_ENTRANCE({ ...UA_gaOptions }));
    }

    if (
      (shouldShowCreditsAlreadyEarnedAggregated || shouldShowCreditsAlreadyEarnedNotAggregated) &&
      !creditsAlreadyEarned
    ) {
      setCreditsAlreadyEarned(true);
      return;
    }
    setLoading(true);
    const response = await CoursesService.launch(course.productId);
    if (response.status.toString().match(SuccessCodeRegex) && response.parsedBody?.url) {
      const url = response.parsedBody.url;
      if (isWebcast(course)) {
        window.open(url, "_blank");
      } else {
        window.location.href = url;
      }
    } else {
      showErrorModal();
    }
    setLoading(false);
  };
  const present = async (event: React.SyntheticEvent) => {
    event?.preventDefault();
    event?.stopPropagation();

    if (isGa4 && gaOptions) {
      sendGACustomEvent(GA_PLAYER_ENTRANCE({ ...gaOptions }));
    }
    if (!isGa4 && UA_gaOptions) {
      UA_sendGACustomEvent(UA_GA_PLAYER_ENTRANCE({ ...UA_gaOptions }));
    }

    setLoading(true);
    const response = await CoursesService.present(course.productId);
    if (response.status.toString().match(SuccessCodeRegex) && response.parsedBody?.url) {
      window.open(response.parsedBody.url, "_blank");
    } else {
      showErrorModal();
    }
    setLoading(false);
  };
  return {
    register,
    unregister,
    buy,
    launch,
    present,
    addToCart,
    loading,
    addingToCart,
    creditsAlreadyEarned,
    setLoading,
    setAddingToCart,
    setCreditsAlreadyEarned,
    hideCreditsAlreadyEarnDialog
  };
}
