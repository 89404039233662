import React from "react";
import ReactDOM from "react-dom/client";
import { StyleSheetManager } from "styled-components";
import { Toasts } from "../../cpe-frontend/src/components/toasts";
import { CPEWrapper } from "./cpe-wrapper";

class CPEToastManager extends HTMLElement {
  private readonly styleHost: HTMLElement;
  private readonly mountPoint: HTMLElement;
  constructor() {
    super();
    this.mountPoint = document.createElement("div");
  }
  connectedCallback() {
    ReactDOM.createRoot(this).render(
      <StyleSheetManager disableCSSOMInjection={true}>
        <CPEWrapper>
          <Toasts />
        </CPEWrapper>
      </StyleSheetManager>
    );
  }
  disconnectedCallback() {
    this.shadowRoot?.removeChild(this.mountPoint);
    this.shadowRoot?.removeChild(this.styleHost);
  }
}
customElements.define("cpe-toast-manager", CPEToastManager);
