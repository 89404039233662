import React, { Dispatch, FC, SetStateAction } from "react";
import { useCart } from "../../../../../../hooks";
import MiniCartButton from "./mini-cart-button";
import MiniCartToggled from "./mini-cart-toggled";

interface Props {
  setReferenceElement: Dispatch<SetStateAction<HTMLElement | null>>;
  referenceElement: HTMLElement | null;
}

const MiniCartToggle: FC<Props> = props => {
  const cart = useCart();

  return (
    <>
      <MiniCartButton setReferenceElement={props?.setReferenceElement} numberItemsInCart={cart.items.length} />
      {cart.isOpen && <MiniCartToggled referenceElement={props?.referenceElement} />}
    </>
  );
};

export default MiniCartToggle;
