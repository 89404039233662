import noop from "lodash/noop";
import React, { FC } from "react";
import ReactDOM from "react-dom/client";
import { StyleSheetManager } from "styled-components";
import { BuyButton } from "../src/components/course-card/components/action-buttons/buy-button";
import { CourseCardProvider, useCourseCardContext } from "../src/components/course-card/course-card-provider";
import { SectionUtilsProvider } from "../src/contexts/section-utils-context";
import { CPEWrapper } from "./cpe-wrapper";

type Size = "small" | "medium" | "large";

const BuyButtonComponent: FC<{ size: Size }> = props => {
  const { addToCart, addingToCart, course } = useCourseCardContext();

  return <BuyButton size={props.size} addToCart={addToCart} addingToCart={addingToCart} course={course} />;
};

class CPEBuyButton extends HTMLElement {
  private readonly styleHost: HTMLElement;
  private readonly mountPoint: HTMLElement;
  constructor() {
    super();
    this.mountPoint = document.createElement("div");
  }
  connectedCallback() {
    ReactDOM.createRoot(this).render(
      <StyleSheetManager disableCSSOMInjection={true}>
        <CPEWrapper>
          <SectionUtilsProvider onRegistry={async () => noop()} onUnregistry={async () => noop()}>
            <CourseCardProvider
              course={JSON.parse(this.getAttribute("course") || "")}
              onToggleSave={async () => noop()}
            >
              <BuyButtonComponent size={this.getAttribute("size") as Size} />
            </CourseCardProvider>
          </SectionUtilsProvider>
        </CPEWrapper>
      </StyleSheetManager>
    );
  }
  disconnectedCallback() {
    this.shadowRoot?.removeChild(this.mountPoint);
    this.shadowRoot?.removeChild(this.styleHost);
  }
}
customElements.define("cpe-buy-button", CPEBuyButton);
