import { Feedback } from "@cpe/models/feedback";
import { createSlice } from "@reduxjs/toolkit";
import { FeedbackThunk } from "./thunks";

interface UserState {
  feedbacks: Feedback[] | undefined;
  loading: boolean;
  error: string | null;
}

export const initialState: UserState = {
  feedbacks: undefined,
  loading: false,
  error: null
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(FeedbackThunk.fulfilled, (state, action) => {
      const response = action.payload;

      if (!response) {
        state.loading = false;
        state.feedbacks = [];
        state.error = "user not authenticated";
        return;
      }
      state.feedbacks = response.requests || [];
      state.loading = false;
      state.error = "";
    });
    builder.addCase(FeedbackThunk.rejected, state => {
      state.loading = false;
      state.feedbacks = [];
      state.error = "user not authenticated";
    });
    builder.addCase(FeedbackThunk.pending, state => {
      state.error = "";
      state.loading = true;
    });
  }
});

export default feedbackSlice.reducer;
