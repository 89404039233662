import isNull from "lodash/isNull";
import { useEffect } from "react";

export const usePreventScroll = (isToPreventScroll: boolean, defaultValue = "unset") => {
  useEffect(() => {
    const mainElement = document.body.querySelector("main");
    const rootElement = document.body.querySelector<HTMLDivElement>("#root");
    if (isToPreventScroll) {
      document.body.style.overflow = "hidden";

      if (!isNull(mainElement)) {
        mainElement.style.overflow = "hidden";
      }
      if (!isNull(rootElement)) {
        rootElement.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = defaultValue;
      if (!isNull(mainElement)) {
        mainElement.style.overflow = defaultValue;
      }
      if (!isNull(rootElement)) {
        rootElement.style.overflow = defaultValue;
      }
    }
    return () => {
      document.body.style.overflow = defaultValue;
      if (!isNull(mainElement)) {
        mainElement.style.overflow = defaultValue;
      }
      if (!isNull(rootElement)) {
        rootElement.style.overflow = defaultValue;
      }
    };
  }, [isToPreventScroll, defaultValue]);
};
