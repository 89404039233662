import React, { FC } from "react";
import {
  GA_CT_TRIAL_UPGRADE_FROM_SELECT_TO_PRIME,
  GA_CT_TRIAL_UPGRADE_ONLY_CT
} from "../../ga/events/compliance/compliance-trial";
import { sendGACustomEvent } from "../../ga/ga";
import { useUser } from "../../hooks";
import { useDeviceBreakpoint } from "../../hooks/use-device-breakpoint";
import { useTranslation } from "../../i18n";
import { UpgradeModalQALabels } from "../../page-objects/components/upgrade-modal/qa-labels";
import { formatedPrice } from "../../utils/price";
import { PromotionCard } from "./components/promotion-card";
import { CardsContainer, Title } from "./styles";
import { PromoModalProps } from "./types";

export const UpgradeEssentials: FC<PromoModalProps> = props => {
  const { ct, upgrade } = props;
  const { isPhoneSD } = useDeviceBreakpoint();
  const { t } = useTranslation(["context_ctTrial"]);
  const { user } = useUser();
  const trialStatus = user.licenses?.ct?.status || "";
  const prime_upgrade_price = upgrade.price;
  const compliance_tracker_add_on_price = ct.price;

  const handleUpgrade = () => {
    sendGACustomEvent(GA_CT_TRIAL_UPGRADE_FROM_SELECT_TO_PRIME(0, trialStatus, true));
    if (!upgrade.link) {
      return;
    }
    if (upgrade.link?.external) {
      window.open(upgrade.link.url, "_blank");
    } else {
      window.location.href = upgrade.link.url;
    }
  };
  const handleBuyCompliance = () => {
    sendGACustomEvent(GA_CT_TRIAL_UPGRADE_ONLY_CT(0, trialStatus));
    if (!ct.link) {
      return;
    }
    if (ct.link?.external) {
      window.open(ct.link.url, "_blank");
    } else {
      window.location.href = ct.link.url;
    }
  };
  return (
    <>
      <Title level="1" data-qa-label={UpgradeModalQALabels.title}>
        {t("context_ctTrial:upgrade.essentials.title", { price: formatedPrice(prime_upgrade_price, 0) })}
      </Title>
      <CardsContainer>
        <PromotionCard
          title={t("context_ctTrial:prime.name")}
          price={t("context_ctTrial:prime.price", { price: formatedPrice(prime_upgrade_price, 0) })}
          advantages={[
            t("context_ctTrial:prime.advantage_1"),
            t("context_ctTrial:prime.advantage_2"),
            t("context_ctTrial:prime.advantage_3")
          ]}
          action={{ name: t("context_ctTrial:prime.upgrade.action"), onAction: handleUpgrade }}
        />
        <PromotionCard
          title={t("context_ctTrial:compliance_tracker.name")}
          price={t("context_ctTrial:compliance_tracker.price", { price: compliance_tracker_add_on_price })}
          type="Alternative"
          advantages={[
            t("context_ctTrial:compliance_tracker.advantage_1"),
            t("context_ctTrial:compliance_tracker.advantage_2"),
            t("context_ctTrial:compliance_tracker.advantage_3")
          ]}
          action={{
            name: isPhoneSD
              ? t("context_ctTrial:compliance_tracker.upgrade.action_mobile")
              : t("context_ctTrial:compliance_tracker.upgrade.action"),
            onAction: handleBuyCompliance
          }}
        />
      </CardsContainer>
    </>
  );
};
