import { useEulaModal } from "../../hooks/use-eula-modal";
import { useTranslation } from "../../i18n";
import { useFeatureFlags } from "../feature-flag/use-feature-flags";

export const useFooterUrls = (
  openComplianceDisclaimer: (onlyClose?: boolean, redirectUrl?: string | undefined) => void
) => {
  const { t } = useTranslation(["context_app"]);
  const { isFFActive } = useFeatureFlags();
  const { handleEulaClick } = useEulaModal();

  const urls: NavigationLink[] = [
    {
      name: t("context_app:contact_us"),
      action: () => openExternalLink("https://www.becker.com/contact-us")
    },
    {
      name: t("context_app:privacy_policy"),
      action: () => openExternalLink("https://www.becker.com/privacy-policy")
    },
    {
      name: t("context_app:compliance_disclaimer"),
      action: () => openComplianceDisclaimer(true)
    },
    {
      name: t("context_app:faq"),
      action: () => (window.location.href = "/help/faqs")
    },
    {
      name: t("context_app:license_agreement"),
      action: () => handleEulaClick()
    },
    {
      name: t("context_app:cookie_policy"),
      action: () => openExternalLink("https://www.becker.com/online-cookie-policy")
    }
  ];

  const urlsOld: NavigationLink[] = [
    {
      name: t("context_app:contact_us"),
      action: () => openExternalLink("https://www.becker.com/contact-us")
    },
    {
      name: t("context_app:privacy_policy"),
      action: () => openExternalLink("https://www.becker.com/privacy-policy")
    },
    {
      name: t("context_app:faq"),
      action: () => (window.location.href = "/help/faqs")
    },
    {
      name: t("context_app:cookie_policy"),
      action: () => openExternalLink("https://www.becker.com/online-cookie-policy")
    }
  ];

  return isFFActive("FOOTER_LINKS_TO_EULA_AND_CT_DISCLAIMER") ? urls : urlsOld;
};
const openExternalLink = (link: string) => window.open(link, "_blank");

interface NavigationLink {
  name: string;
  action: () => void;
  id?: string;
}
