import React from "react";
import styled from "styled-components";
import { useCart } from "../../../hooks";
import { useDeviceBreakpoint } from "../../../hooks/use-device-breakpoint";
import { usePreventScroll } from "../../../hooks/use-prevent-scroll";
import { media } from "../../../utils/media-queries";
import EmptyMiniCart from "./empty-mini-cart";
import MiniCartWithItems from "./mini-cart-with-items";

const MiniCart: React.FC = () => {
  const cart = useCart();
  const { isPhone } = useDeviceBreakpoint();

  usePreventScroll(isPhone);

  return (
    <Wrapper windowHeight={window.innerHeight}>
      {cart?.items?.length ? <MiniCartWithItems /> : <EmptyMiniCart />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ windowHeight: number }>`
  width: 18rem; /* 288px */
  max-height: 34.625rem; /* 554px */
  padding: 1rem; /* 16px */
  background-color: ${props => props.theme.colors.brand01[800]};
  border-radius: 0.25rem; /* 4px */
  display: flex;
  flex-direction: column;

  ${media.phoneHD.max} {
    max-height: ${props =>
      props.windowHeight &&
      `calc(${props.windowHeight}px - 2.5rem - 0.4375rem - 3rem)`}; // 2.5rem of the header, 0.4375rem of the arrow icon, 3rem of paddings/margins
    width: calc(100vw - 4rem);
  }
`;

export default MiniCart;
