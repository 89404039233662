import { Typography } from "@beckersdp/becker-ds";
import { CartItemModel } from "@cpe/models/cart";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "../../../i18n";
import { MiniCartQALabels } from "../../../page-objects/components/mini-cart/qa-labels";

const MiniCartItem: React.FC<CartItemModel> = props => {
  const { t } = useTranslation(["cart"]);

  return (
    <WrapperLi data-qa-label={MiniCartQALabels.miniCartItem}>
      <Text level="2" weight="medium" color="primary-inverted">
        {props.name}
      </Text>
      <Text level="2" weight="medium" color="primary-inverted">
        {t("cart:price", { val: props.price })}
      </Text>
    </WrapperLi>
  );
};

const WrapperLi = styled.li`
  display: flex;
  flex-direction: column;
  border-bottom: 0.0625rem solid ${props => props.theme.colors.brand01[700]};
  margin: 1rem 0; /* 16px 0 */
  padding-bottom: 1rem;

  &:nth-child(1) {
    margin-top: 0;
  }

  &:nth-last-child(1) {
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

const Text = styled(Typography.Text)`
  &:nth-child(2) {
    padding-top: 1rem; /* 16px */
  }
`;

export default MiniCartItem;
