import { Col, Container, Row, Typography } from "@beckersdp/becker-ds";
import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "../../i18n";
import { media } from "../../utils/media-queries";
import useComplianceDisclaimerContext from "../compliance-legal-disclaimer/compliance-disclaimer-provider";
import ReviewedCopyright from "../copyright/reviewed-copyright";
import { useFooterUrls } from "./use-footer-urls";

export const FooterContent: FC = () => {
  const { t } = useTranslation(["context_app"]);
  const { openComplianceDisclaimer } = useComplianceDisclaimerContext();

  const links = useFooterUrls(openComplianceDisclaimer);

  return (
    <>
      <GridContainer withoutMargins={true} fluid={false}>
        <Row>
          <Col
            phoneSD={6}
            phoneHD={12}
            tablet={12}
            desktopHD={24}
            offsetDesktopHD={2}
            offsetDesktopSD={1}
            offsetTablet={1}
            style={{ marginBottom: "8px" }}
          >
            <Typography.Subtitle level="2">{t("context_app:footer.futher_info")}</Typography.Subtitle>
          </Col>
          <Col
            phoneSD={6}
            phoneHD={12}
            tablet={6}
            desktopHD={10}
            desktopSD={6}
            offsetDesktopHD={2}
            offsetDesktopSD={1}
            offsetTablet={1}
          >
            <Ul>
              {links.map((link, index) => (
                <Li key={index}>
                  <Typography.Link id={link.id} onClick={link.action}>
                    {link.name}
                  </Typography.Link>
                </Li>
              ))}
            </Ul>
          </Col>
          <CopyrightCol
            phoneSD={6}
            phoneHD={12}
            tablet={8}
            desktopHD={8}
            desktopSD={4}
            offsetDesktopHD={1}
            offsetTablet={1}
          >
            <ReviewedCopyright />
          </CopyrightCol>
        </Row>
      </GridContainer>
    </>
  );
};

const GridContainer = styled(Container)`
  background-color: ${props => props.theme.colors.brand01.default};
  padding: 2rem 0;
  ${Typography.Subtitle}, ${Typography.Link} {
    color: ${props => props.theme.colors.white};
  }

  ${media.phoneHD.max} {
    padding: 16px 16px;
  }
`;

const CopyrightCol = styled(Col)`
  height: auto;
  display: flex;
  align-items: baseline;
  ${media.tablet.max} {
    padding-top: 2rem;
  }
`;

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const Li = styled.li`
  flex-basis: 50%;
  margin-top: 4px;
  a {
    font-size: 12px;
  }
  text-decoration: underline;
  ${media.tablet.min} {
    flex-basis: 33%;
  }
`;
