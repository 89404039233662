import { AvailableFeaturesFlags, FeaturesFlags } from "@cpe/models/feature-flag";
import React, { FC } from "react";
import { useFeatureFlags } from "./use-feature-flags";

interface Props {
  allowedFlags: AvailableFeaturesFlags[];
}

export const isAllowedFlag = (allowedFlags: AvailableFeaturesFlags[], featuresFlags?: FeaturesFlags): boolean => {
  let isAllowed = false;
  if (allowedFlags.length === 0) {
    return isAllowed;
  }
  allowedFlags.forEach((flag: AvailableFeaturesFlags, index: number) => {
    if (index === 0 && featuresFlags?.[flag] === true) {
      isAllowed = true;
    }
    if (featuresFlags?.[flag] === true) {
      isAllowed = isAllowed && Boolean(featuresFlags?.[flag]);
    }
  });
  return isAllowed || false;
};

export const FeatureFlag: FC<Props & { children: React.ReactNode }> = props => {
  const { allowedFlags, children } = props;
  const { featuresFlags } = useFeatureFlags();
  if (isAllowedFlag(allowedFlags, featuresFlags)) {
    return <>{children}</>;
  }
  return null;
};
