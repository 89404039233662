import { Button, Input } from "@beckersdp/becker-ds";
import { RecoverResponse } from "@cpe/services/authentication";
import { HttpResponse } from "@cpe/services/fetch-util";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "../../i18n";
import { RecoverPasswordQaLabels } from "../../page-objects/pages/recover-password/qa-labels";
import { availableRoutes } from "../../router/routes";
import { useForgotPasswordForm } from "./use-forgot-password-form";

interface ComponentProps {
  hideNeedMoreHelpLink?: boolean;
  onSubmit: (response: HttpResponse<RecoverResponse>, email: string) => void;
}

export const ForgotPasswordForm: FC<ComponentProps> = props => {
  const { onSubmit } = props;
  const { t } = useTranslation(["recoverPassword"]);
  const history = useHistory();
  const {
    values,
    errors,
    touched,
    dirty,
    isValid,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting
  } = useForgotPasswordForm({ onSubmit });
  return (
    <Container>
      <Text>{t("recoverPassword:findAccount")}</Text>
      <Form onSubmit={handleSubmit}>
        <StyledInput
          id="email"
          label={t("recoverPassword:email")}
          onChange={handleChange}
          onBlur={handleBlur}
          onClear={() => setFieldValue("email", "")}
          value={values.email}
          qaLabel={RecoverPasswordQaLabels.emailInput}
          error={touched.email && errors.email ? errors.email : ""}
        />
        <div>
          <StyledButton
            data-qa-label={RecoverPasswordQaLabels.submitButton}
            loading={isSubmitting}
            disabled={!(dirty && isValid)}
            type="primary"
            htmlType="submit"
          >
            {t("recoverPassword:submit")}
          </StyledButton>
        </div>
      </Form>
      <div>
        <StyledButton
          data-qa-label={RecoverPasswordQaLabels.backToLoginButton}
          onClick={() => (history.length > 0 ? history.goBack() : history.push(availableRoutes.generalSignIn))}
          icon="arrow-left"
        >
          {t("recoverPassword:backToLogin")}
        </StyledButton>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Text = styled.div`
  ${props => props.theme.typography.types.caption2}
`;

const StyledInput = styled(Input)`
  margin-bottom: 2rem;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
const Form = styled.form`
  width: 100%;

  ${Input} {
    margin-top: 30px;
  }

  ${StyledButton} {
    margin-top: 0.625rem;
  }

  margin-bottom: 1.875rem;
`;
