import { Icon, Typography } from "@beckersdp/becker-ds";
import React, { Dispatch, FC, SetStateAction } from "react";
import styled from "styled-components";
import { useCart } from "../../../../../../hooks";
import { MiniCartQALabels } from "../../../../../../page-objects/components/mini-cart/qa-labels";
import { useAppDispatch } from "../../../../../../store";
import { setMiniCartOpenStatus } from "../../../../../../store/cart";
import { media } from "../../../../../../utils/media-queries";
import { IconButton } from "../../../../../icon-button";

interface Props {
  setReferenceElement?: Dispatch<SetStateAction<HTMLElement | null>>;
  numberItemsInCart: number;
}

const MiniCartButton: FC<Props> = ({ setReferenceElement, numberItemsInCart }) => {
  const dispatch = useAppDispatch();
  const cart = useCart();

  const toogleCart = () => dispatch(setMiniCartOpenStatus());

  return (
    <Wrapper ref={setReferenceElement}>
      <StyledIconButton
        aria-label="toogle-mini-cart"
        $hasItems={numberItemsInCart > 0}
        data-qa-label={MiniCartQALabels.toggleButton}
        data-loading={cart.loading}
        onClick={toogleCart}
      >
        {numberItemsInCart > 0 && (
          <NumberItemsTypography
            level="3"
            weight="bold"
            color="primary-inverted"
            wide={numberItemsInCart?.toString()?.length}
            data-qa-label={MiniCartQALabels.miniCartItemsLength}
          >
            {numberItemsInCart}
          </NumberItemsTypography>
        )}
        <Icon name="cart" style={{ flexShrink: 0 }} />
      </StyledIconButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)<{ $hasItems: boolean }>`
  width: 24px;
  position: relative;
  color: ${props => props.theme.colors.brand01[600]};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  &:hover {
    color: ${props => props.theme.colors.brand01.default};
  }

  ${media.desktopSD.min} {
    padding-top: ${props => (props.$hasItems ? "7px" : "")};
    height: 58px;
    padding-left: 6px;
    margin-top: 12px;
    padding-bottom: 12px;
    width: 28px;
    ${Icon} {
      width: 2.3rem;
      height: 2.1rem;
    }
  }
`;

const NumberItemsTypography = styled(Typography.Text)<{ wide: number }>`
  position: absolute;
  top: -0.5rem; /* 4px */
  right: -0.5rem; /* 4px */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${props => (props.wide > 2 ? "1.50rem" : "1.25rem")}; /* 24px / 20px */
  height: ${props => (props.wide > 2 ? "1.50rem" : "1.25rem")}; /* 24px / 20px */
  background: ${props => props.theme.colors.brand03.default};
  ${media.desktopSD.min} {
    top: 7px; /* 4px */
  }
`;

export default MiniCartButton;
