import React, { FC } from "react";
import { ComplianceSectionProvider } from "../../../../pages/home/components/compliance-section/compliance-section-provider";
import { usePopupMessages } from "../../../../pages/home/components/popup-messages/use-popup-messages";
import { ComplianceLegalDisclaimer } from "../../../compliance-legal-disclaimer";
import { ComplianceDisclaimerProvider } from "../../../compliance-legal-disclaimer/compliance-disclaimer-provider";
import { DesktopHeader } from "./components/desktop-header";
import { MobileHeader } from "./components/mobile-header";

export const Header: FC = () => {
  const isDesktopHeader = window.innerWidth > 979;
  const { setRemoteDismiss, feebacksToShow, isLoadingFeedbacks } = usePopupMessages();
  return (
    <ComplianceSectionProvider
      alertsToShow={[]}
      setRemoteDismiss={setRemoteDismiss}
      feebacksToShow={feebacksToShow}
      isLoadingFeedbacks={isLoadingFeedbacks}
    >
      <ComplianceDisclaimerProvider>
        <ComplianceLegalDisclaimer onlyCloseModal={false} />
        {isDesktopHeader ? <DesktopHeader /> : <MobileHeader />}
      </ComplianceDisclaimerProvider>
    </ComplianceSectionProvider>
  );
};
