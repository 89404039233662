import React, { FC } from "react";
import { FooterContent } from "./footer-content";

export const Footer: FC = () => {
  return (
    <footer>
      <FooterContent />
    </footer>
  );
};
