import { Banner } from "@beckersdp/becker-ds";
import React, { FC } from "react";
import styled from "styled-components";
import { useUser } from "../../hooks";
import { useTranslation } from "../../i18n";
import { PageQALabels } from "../../page-objects/components/page/qa-labels";
import { useFeatureFlags } from "../feature-flag/use-feature-flags";

export const ImpersonateBanner: FC = () => {
  const { isFFActive } = useFeatureFlags();
  const { user } = useUser();
  const { t } = useTranslation(["context_app"]);
  if (!isFFActive("UI_FOR_IMPERSONATING_ACCOUNT") || !user.isImpersonating) {
    return null;
  }
  return (
    <StyledBanner
      type="warning"
      data-qa-label={PageQALabels.impersonateBanner}
      btnProps={{
        onClick: () => {
          window.location.href = "/authentication/impersonate/exit";
        }
      }}
      btnText={t("context_app:impersonate_banner.action")}
      title={t("context_app:impersonate_banner.title")}
    >
      {t("context_app:impersonate_banner.description")}
    </StyledBanner>
  );
};

const StyledBanner = styled(Banner)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
