import { Typography } from "@beckersdp/becker-ds";
import { NavigationItem } from "@cpe/models/navigation-link";
import kebabCase from "lodash/kebabCase";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { SessionStorageType } from "../../../../../../../../constants/storage";
import { useComplianceSectionContext } from "../../../../../../../../pages/home/components/compliance-section/compliance-section-provider";
import { availableRoutes } from "../../../../../../../../router/routes";
import useComplianceDisclaimerContext from "../../../../../../../compliance-legal-disclaimer/compliance-disclaimer-provider";
import { CompliancePopover } from "../../../compliance-popover/compliance-popover";
interface Props {
  item: NavigationItem;
}
export const MainLinkItem: FC<Props> = props => {
  const { item } = props;
  const { shouldShowComplianceDisclaimer, openComplianceDisclaimer } = useComplianceDisclaimerContext();
  const complianceCredits = useComplianceSectionContext();
  const { pathname } = useLocation();
  const end = false;
  const endSlashPosition = item.url !== "/" && item.url.endsWith("/") ? item.url.length - 1 : item.url.length;
  const isActive =
    pathname === item.url.split("?")[0] ||
    (!end && pathname.startsWith(item.url) && pathname.charAt(endSlashPosition) === "/");
  const isComplianceLink = item.url === availableRoutes.compliance;
  const showCompliancePopover =
    isComplianceLink && sessionStorage[SessionStorageType.HOME_COMPLIANCE_OLD_TRACKER_POPOVER];
  const hasLicenses = complianceCredits?.complianceCredits && complianceCredits?.complianceCredits?.length > 0;

  const renderLink = () => {
    return (
      <LI
        role="list-item"
        className={`${isActive ? "active" : ""}`}
        href={item.url}
        target={item.external ? "_blank" : ""}
        data-qa-label={`${kebabCase(item.label)}-link`}
        onClick={event => {
          if (isComplianceLink && hasLicenses && shouldShowComplianceDisclaimer()) {
            event.preventDefault();
            openComplianceDisclaimer();
            return;
          }
        }}
      >
        <Typography.Text level="2" weight="bold">
          {item.label}
        </Typography.Text>
      </LI>
    );
  };

  if (showCompliancePopover) {
    return <CompliancePopover>{renderLink()}</CompliancePopover>;
  }

  return renderLink();
};

const LI = styled.a`
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding-top: 7px;
  border-bottom: solid 4px transparent;
  white-space: nowrap;
  text-decoration: none;

  &&:hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
  &&:hover {
    border-bottom: solid 4px ${props => props.theme.colors.brand01[700]};
  }
  &.active {
    border-bottom: solid 4px ${props => props.theme.colors.brand01.default};
  }
`;
