import { Icon, Popover, Typography } from "@beckersdp/becker-ds";
import { NavigationElement, NavigationGroup, NavigationItem } from "@cpe/models/navigation-link";
import kebabCase from "lodash/kebabCase";
import React, { FC, RefObject, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useClickOutside } from "../../../../../../../../hooks/use-click-outside";
import { SubMenu } from "./components/sub-menu";
interface Props {
  title: string;
  item: NavigationGroup;
}
export const MainLinkGroup: FC<Props> = props => {
  const { title, item } = props;
  const { pathname } = useLocation();
  const isAnySubItemActive = (items: NavigationElement): boolean => {
    const activeItems =
      items.filter(item => {
        const _item = item as unknown;
        if ((_item as NavigationGroup)?.items?.length > 0) {
          return isAnySubItemActive((_item as NavigationGroup).items);
        }
        const end = false;
        const endSlashPosition =
          (_item as NavigationItem).url !== "/" && (_item as NavigationItem).url.endsWith("/")
            ? (_item as NavigationItem).url.length - 1
            : (_item as NavigationItem).url.length;
        const isActive =
          pathname === (_item as NavigationItem).url.split("?")[0] ||
          (!end && pathname.startsWith((_item as NavigationItem).url) && pathname.charAt(endSlashPosition) === "/");
        return isActive;
      }) || [];
    return activeItems.length > 0;
  };

  const isGroupActive = isAnySubItemActive(item.items);
  const [isOpen, setOpen] = useState(false);
  const [groupLinkElement, setGroupLinkElement] = useState<HTMLElement | null>(null);
  const groupLinkElementAsRefCurrent = groupLinkElement as any as RefObject<HTMLElement>;
  useClickOutside(() => {
    setOpen(false);
  }, [groupLinkElementAsRefCurrent]);
  return (
    <div ref={setGroupLinkElement} style={{ height: "100%" }}>
      <CustomPopover
        placement="bottom"
        visible={isOpen}
        offset={[0, 0]}
        triggerEvents={["click", "focus"]}
        content={<SubMenu data-qa-label={`${kebabCase(item.label)}-submenu`} items={item.items} />}
      >
        <LI
          className={`${isGroupActive ? "active" : ""}`}
          role="listitem"
          onClick={() => setOpen(!isOpen)}
          data-qa-label={`${kebabCase(item.label)}-link`}
        >
          <div style={{ height: "100%" }} tabIndex={0}>
            <CustomText level="2" weight="bold">
              {title}
            </CustomText>
          </div>
          <CustomIcon name="chevron-down" />
        </LI>
      </CustomPopover>
    </div>
  );
};
const CustomIcon = styled(Icon)`
  height: 18px;
`;

const CustomPopover = styled(Popover)``;

const CustomText = styled(Typography.Text)`
  height: 100%;
  border-bottom: solid 4px transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const LI = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  height: 62px;
  margin: 0;
  padding: 0;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding-top: 8px;
  gap: 10px;
  color: ${props => props.theme.colors.brand01[700]};
  height: 100%;

  & :hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
  &&:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.brand01.default};
  }
  &.active {
    color: ${props => props.theme.colors.brand01.default};
    ${CustomText} {
      border-bottom: solid 4px ${props => props.theme.colors.brand01.default};
    }
  }
`;
