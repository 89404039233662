import React, { FC, useEffect } from "react";
import { Header } from "../../src/components/page/components/header";
import { LocalStorageType } from "../../src/constants/storage";
import { useFeedback, useStorage } from "../../src/hooks";
import { ComplianceSectionProvider } from "../../src/pages/home/components/compliance-section/compliance-section-provider";
import { usePopupMessages } from "../../src/pages/home/components/popup-messages/use-popup-messages";
import { useAppDispatch } from "../../src/store";
import { FeedbackThunk } from "../../src/store/feedback/thunks";
import { removeStorageItem, storageKeys } from "../../src/store/storage";

export const ExportedHeader: FC = () => {
  const { setRemoteDismiss, feebacksToShow, isLoadingFeedbacks } = usePopupMessages();
  const { feedbacks } = useFeedback();
  const { localStorage } = useStorage();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!feedbacks && !isLoadingFeedbacks) {
      dispatch(FeedbackThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear tab filters from users compliance upon navigation to legacy pages
  // similar solution as we did on routes.tsx -> useStorageContextCleaner
  useEffect(() => {
    if (localStorage.compliance_users_filters) {
      dispatch(
        removeStorageItem({
          key: LocalStorageType.COMPLIANCE_USERS_FILTERS,
          type: storageKeys.localStorage
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ComplianceSectionProvider
      alertsToShow={[]}
      setRemoteDismiss={setRemoteDismiss}
      feebacksToShow={feebacksToShow}
      isLoadingFeedbacks={isLoadingFeedbacks}
    >
      <Header />
    </ComplianceSectionProvider>
  );
};
