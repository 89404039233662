import { Icon, Popover, Typography } from "@beckersdp/becker-ds";
import { NavigationItem } from "@cpe/models/navigation-link";
import React, { RefObject, useState } from "react";
import styled from "styled-components";
import { SessionStorageType } from "../../../../../../../../constants/storage";
import { useNavigation } from "../../../../../../../../hooks";
import { useClickOutside } from "../../../../../../../../hooks/use-click-outside";
import { media } from "../../../../../../../../utils/media-queries";
import { useFeatureFlags } from "../../../../../../../feature-flag/use-feature-flags";
import { AccountSetting } from "../../../account-settings";
import { CompliancePopover } from "../../../compliance-popover/compliance-popover";
import { HamburgerButton } from "../hamburger-button";
import { MobileNavLink } from "./mobile-nav-link";

export const NavPopover = () => {
  const [open, setOpen] = useState(false);
  const { main, helpLinks, support } = useNavigation();
  const { isFFActive } = useFeatureFlags();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const popperElementAsRefCurrent = popperElement as any as RefObject<HTMLElement>;
  useClickOutside(() => {
    setOpen(false);
  }, [popperElementAsRefCurrent]);
  const showCompliancePopover = sessionStorage[SessionStorageType.HOME_COMPLIANCE_OLD_TRACKER_POPOVER];

  const renderButton = () => {
    return (
      <Container ref={setPopperElement}>
        <Popover
          placement="bottom-end"
          offset={[16, 16]}
          content={
            <div style={{ display: "flex", flexFlow: "column" }}>
              <CustomAccountSettings />
              {main.map(link => {
                if (link.label !== "Admin") {
                  return <MobileNavLink key={link.label} link={link as NavigationItem} />;
                }
                return null;
              })}
              {isFFActive("BECKER_HELP_CENTER_LINK") &&
                support.map(link => <MobileNavLink key={link.label} link={link as NavigationItem} />)}
              {helpLinks.map(link => (
                <MobileNavLink key={link.label} link={link as NavigationItem} />
              ))}
            </div>
          }
          visible={open}
          triggerEvents={["click"]}
        >
          <div role="listitem">
            <HamburgerButton
              open={open}
              onClick={() => {
                setOpen(!open);
              }}
            />
          </div>
        </Popover>
      </Container>
    );
  };

  return (
    <div>
      {showCompliancePopover ? (
        <CompliancePopover placement="bottom-start" offset={[8, 8]}>
          {renderButton()}
        </CompliancePopover>
      ) : (
        renderButton()
      )}
    </div>
  );
};
const CustomAccountSettings = styled(AccountSetting)`
  margin: 8px 16px 32px 16px;
`;
const Container = styled.div`
  #popper-arrow {
    display: none;
  }
  [data-popper-placement="bottom-end"] {
    width: 100%;
    z-index: -1;
    ${media.tablet.min} {
      width: 50%;
      height: 100vh;
      background-color: ${props => props.theme.colors.white};
      z-index: -1;
    }
  }
  [data-qa-label="popover-wrapper-card"] {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Text = styled(Typography.Text)`
  color: ${props => props.theme.colors?.brand01[700]};
`;

export const A = styled.a<{ $isActive: boolean }>`
  box-sizing: border-box;
  height: 52px;
  border-top: solid 2px ${props => props.theme.colors.support01[300]};
  padding-left: 20px;
  display: inline-flex;
  gap: 20px;
  align-items: center;

  color: ${props => (props.$isActive ? props.theme.colors?.brand01.default : props.theme.colors?.brand01[700])};
  &.selected {
    color: ${props => props.theme.colors?.brand01.default};
  }
  &:link,
  :visited {
    text-decoration: inherit;
    color: ${props => props.theme.colors?.brand01.default};
  }
  &:hover {
    text-decoration: none;
  }
  ${Text} {
    color: ${props => (props.$isActive ? props.theme.colors?.brand01.default : props.theme.colors?.brand01[700])};
  }
  ${Icon} {
    color: ${props => (props.$isActive ? props.theme.colors?.brand01.default : props.theme.colors?.brand01[700])};
  }
`;
