import { Alert, Button } from "@beckersdp/becker-ds";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "../../i18n";
import { RecoverPasswordQaLabels } from "../../page-objects/pages/recover-password/qa-labels";
import { availableRoutes } from "../../router/routes";

interface Props {
  email: string;
}

export const EmailSent: FC<Props> = props => {
  const { t } = useTranslation(["recoverPassword"]);
  const history = useHistory();
  return (
    <>
      <StyledAlert data-qa-label={RecoverPasswordQaLabels.emailSuccessMessage} type="success">
        {t("recoverPassword:emailSent", { email: props.email })}
      </StyledAlert>
      <StyledButton
        data-qa-label={RecoverPasswordQaLabels.backToLoginButton}
        onClick={() => history.push(availableRoutes.generalSignIn)}
        icon="arrow-left"
      >
        {t("recoverPassword:backToLogin")}
      </StyledButton>
    </>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: 2.5rem;
`;
const StyledButton = styled(Button)`
  width: 100%;
`;
