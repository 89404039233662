import { SyntheticEvent } from "react";

export const getLinkProps = (href: string) => {
  return {
    href,
    onClick: (e: SyntheticEvent) => {
      e.preventDefault();
      window.open(href);
    }
  };
};
