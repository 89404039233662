import { Course } from "@cpe/models/course";
import { CourseDetailsResponseModel } from "@cpe/services/courses";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CourseDetails, SearchWebcast } from "./thunks";

export interface CoursesDetailsState {
  details: CourseDetailsResponseModel | undefined;
  courses: Course[] | undefined;
  loading: boolean;
  error: string | null;
}
export const initialState: CoursesDetailsState = {
  details: undefined,
  courses: undefined,
  loading: false,
  error: null
};

export const courseDetailsSlice = createSlice({
  name: "courses",
  initialState: initialState,
  reducers: {
    registerCourseDetails: state => {
      if (state.details) {
        state.details.actions = state.details?.actions.filter(action => action !== "REGISTER");
        state.details.actions.push("UNREGISTER");
        state.details.state.registered = true;
      }
    },
    registerCourseWebcast: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].actions = state.courses[courseIndex].actions.filter(action => action !== "REGISTER");
        state.courses[courseIndex].actions.push("UNREGISTER");
        state.courses[courseIndex].state.registered = true;
      }
    },
    unregisterCourseDetails: state => {
      if (state.details) {
        state.details.actions = state.details?.actions.filter(action => action !== "UNREGISTER");
        state.details.actions.push("REGISTER");
        state.details.state.registered = false;
      }
    },
    unregisterCourseWebcast: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].actions = state.courses[courseIndex].actions.filter(
          action => action !== "UNREGISTER"
        );
        state.courses[courseIndex].actions.push("REGISTER");
        state.courses[courseIndex].state.registered = false;
      }
    },
    joinWebcastCourseDetails: state => {
      if (state.details) {
        state.details.status = "IN_PROGRESS";
      }
    },
    joinWebcastCourseWebcast: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].status = "IN_PROGRESS";
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(CourseDetails.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
        state.details = undefined;
      } else {
        state.details = action.payload;
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(CourseDetails.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(CourseDetails.pending, state => {
      state.error = "";
      state.loading = true;
    });
    builder.addCase(SearchWebcast.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
        state.courses = undefined;
      } else {
        state.courses = action.payload?.courses;
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(SearchWebcast.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(SearchWebcast.pending, state => {
      state.error = "";
      state.loading = true;
    });
  }
});

export const {
  registerCourseDetails,
  registerCourseWebcast,
  unregisterCourseDetails,
  unregisterCourseWebcast,
  joinWebcastCourseDetails,
  joinWebcastCourseWebcast
} = courseDetailsSlice.actions;
export default courseDetailsSlice.reducer;
