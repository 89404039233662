import { CourseDetailsResponseModel, CoursesResponseModel, CoursesService } from "@cpe/services/courses";
import { ErrorResponse } from "@cpe/services/fetch-util";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const CourseDetails = createAsyncThunk<
  (ErrorResponse & CourseDetailsResponseModel) | undefined,
  string | undefined,
  any
>("course/details/get", async (productId: string) => (await CoursesService.getCourseDetails(productId)).parsedBody);

export const SearchWebcast = createAsyncThunk<
  (ErrorResponse & CoursesResponseModel) | undefined,
  string | undefined,
  any
>("search/webcast/get", async (search: string) => (await CoursesService.searchWebcasts(search)).parsedBody);
