import { FeedbackToastType } from "@beckersdp/becker-ds/dist/toast/card/feedback/types";
import { InformativeToastType } from "@beckersdp/becker-ds/dist/toast/card/informative/types";
import { NotificationToastType } from "@beckersdp/becker-ds/dist/toast/card/notification/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import { ToastIdsEnum } from "../../constants/toast-ids";

export type InformativeTypeKeys = InformativeToastType & {
  id: ToastIdsEnum;
  toastType: "informative";
  isToShowAfterRedirect?: boolean;
};

export type NotificationTypeKeys = NotificationToastType & {
  id: ToastIdsEnum;
  toastType: "notification";
  isToShowAfterRedirect?: boolean;
};

export type FeedbackTypeKeys = FeedbackToastType & {
  id: ToastIdsEnum;
  toastType: "feedback";
  isToShowAfterRedirect?: boolean;
};

interface ToastState {
  toasts: any[];
}

const setInitialToastState = () => {
  const initialToasts: any = [];
  Object.keys(window.sessionStorage).forEach((key: ToastIdsEnum) => {
    const storageItem = window.sessionStorage.getItem(key);
    const isObject = storageItem?.startsWith("{") && storageItem?.endsWith("}") && storageItem.includes(":");
    const item = isEmpty(storageItem) || isNull(storageItem) ? "{}" : isObject ? JSON.parse(storageItem) : storageItem;
    if (Object.values(ToastIdsEnum).includes(key)) {
      const { isToShowAfterRedirect, ...rest } = item;
      initialToasts.push(rest);
    }
  });
  return initialToasts;
};

export const initialState: ToastState = {
  toasts: setInitialToastState()
};

export const toastSlice = createSlice({
  name: "toasts",
  initialState: initialState,
  reducers: {
    setToastItem: (state, action: PayloadAction<any>) => {
      if (!state.toasts?.find((toast: any) => toast.id === action.payload.id)) {
        state.toasts = [...state.toasts, action.payload];
        window.sessionStorage.setItem(action.payload.id, JSON.stringify(action.payload));
      }
    },
    removeToastItem: (state, action: PayloadAction<ToastIdsEnum>) => {
      state.toasts = [...state.toasts.filter(toast => toast.id !== action.payload)];
      window.sessionStorage.removeItem(action.payload);
    }
  }
});

export const { setToastItem, removeToastItem } = toastSlice.actions;
export default toastSlice.reducer;
