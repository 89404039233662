import {
  FloatingArrow,
  Placement,
  arrow,
  flip,
  offset as offsetFloating,
  shift,
  useFloating
} from "@floating-ui/react";
import React, { FC, useRef, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../../../../../hooks/store";
import { useClickOutside } from "../../../../../../hooks/use-click-outside";
import { useDeviceBreakpoint } from "../../../../../../hooks/use-device-breakpoint";
import { useFloatingPositionUpdate } from "../../../../../../hooks/use-floating-position-update";
import { MiniCartQALabels } from "../../../../../../page-objects/components/mini-cart/qa-labels";
import { setMiniCartOpenStatus } from "../../../../../../store/cart";
import { media } from "../../../../../../utils/media-queries";
import MiniCart from "../../../../../cart/mini-cart";

interface Props {
  referenceElement: HTMLElement | null;
}

const placement: Placement = "bottom-end";

const MiniCartToggled: FC<Props> = ({ referenceElement }) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const arrowElement = useRef(null);
  const { isPhone, isTablet } = useDeviceBreakpoint();

  const dispatch = useAppDispatch();
  const toogleCart = () => dispatch(setMiniCartOpenStatus());

  const offset = () => {
    if (!isPhone) {
      if (placement === "bottom-end") {
        return [30, isTablet ? 7 : 0];
      }
      return [0, isTablet ? 7 : 0];
    }
    return [0, 7];
  };
  const { floatingStyles, elements, context, update } = useFloating({
    elements: {
      reference: referenceElement,
      floating: popperElement
    },
    middleware: [
      flip(), // not overflowing the Y viewport edge
      shift(), // not overflowing the X viewport edge
      offsetFloating({
        mainAxis: offset()?.[1],
        crossAxis: offset()?.[0]
      }),
      arrow({
        element: arrowElement
      })
    ],
    placement: "bottom-end",
    strategy: "absolute"
  });

  useFloatingPositionUpdate({
    reference: elements?.reference,
    floating: elements?.floating,
    update
  });

  useClickOutside(toogleCart, [elements.reference as any, elements.floating]);

  return (
    <Div ref={setPopperElement} style={floatingStyles} data-qa-label={MiniCartQALabels.toggledWrapper}>
      <FloatingArrow ref={arrowElement} id="arrow" context={context} />
      <MiniCart />
    </Div>
  );
};

const Div = styled.div`
  ${media.phoneHD.max} {
    padding: 0 1rem;
  }

  #arrow {
    color: ${props => props.theme.colors.brand01[800]};
    fill: ${props => props.theme.colors.brand01[800]};
  }
`;

export default MiniCartToggled;
