import { Authentication, RecoverResponse } from "@cpe/services/authentication";
import { HttpResponse } from "@cpe/services/fetch-util";
import { useFormik } from "formik";
import * as yup from "yup";

interface PasswordForm {
  onSubmit?: (response: HttpResponse<RecoverResponse>, email: string) => void;
}

export const useForgotPasswordForm = (params: PasswordForm) => {
  const RecoverPasswordSchema = yup.object().shape({
    email: yup.string().email().required()
  });
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: RecoverPasswordSchema,
    onSubmit: async values => {
      const response = await Authentication.accountRecover({ username: values.email });
      params?.onSubmit?.(response, values.email);
    }
  });
  return {
    ...formik
  };
};
