import { CPEAnalyticsEvent } from "../../ga";

export const GA_CT_TRIAL_UPGRADE = (value: number, trialStatus: string): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT-TRIAL",
    eventAction: "UPGRADE_NOW",
    step: trialStatus,
    eventValue: value
  };
};

export const GA_CT_TRIAL_RENEW = (value: number, trialStatus: string): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT-TRIAL",
    eventAction: "RENEW",
    step: trialStatus,
    eventValue: value
  };
};

export const GA_CT_TRIAL_START = (value: number): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT-HOME-TRIAL",
    eventAction: "START",
    eventValue: value
  };
};

export const GA_CT_TRIAL_TELL_MORE = (value: number, trialStatus: string): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT-TRIAL",
    eventAction: "TELL_MORE",
    step: trialStatus,
    eventValue: value
  };
};

export const GA_CT_TRIAL_UPGRADE_ONLY_CT = (value: number, trialStatus: string): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT-TRIAL",
    eventAction: "UPGRADE_ONLY_CT",
    step: trialStatus,
    eventValue: value
  };
};

export const GA_CT_RENEW_SELECT_PLUS_CT = (value: number, trialStatus: string): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT",
    eventAction: "RENEW_SELECT_PLUS_CT",
    step: trialStatus,
    eventValue: value
  };
};

export const GA_CT_RENEW_ESSENTIALS_PLUS_CT = (value: number, trialStatus: string): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT",
    eventAction: "RENEW_ESSENTIALS_PLUS_CT",
    step: trialStatus,
    eventValue: value
  };
};

export const GA_CT_TRIAL_UPGRADE_FROM_SELECT_TO_PRIME = (
  value: number,
  trialStatus: string,
  reducedPrice = false
): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT-TRIAL",
    eventAction: "UPGRADE_FROM_SELECT_TO_PRIME",
    context: reducedPrice ? "BEFORE_90_DAYS" : "AFTER_90_DAYS",
    step: trialStatus,
    eventValue: value
  };
};

export const GA_CT_TRIAL_UPGRADE_FROM_ESSENTIALS_TO_PRIME = (
  value: number,
  trialStatus: string,
  reducedPrice = false
): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT-TRIAL",
    eventAction: "UPGRADE_FROM_ESSENTIALS_TO_PRIME",
    context: reducedPrice ? "BEFORE_90_DAYS" : "AFTER_90_DAYS",
    step: trialStatus,
    eventValue: value
  };
};

export const GA_CT_TRIAL_BUY_PRIME = (value: number, trialStatus: string): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT-TRIAL",
    eventAction: "BUY_PRIME",
    step: trialStatus,
    eventValue: value
  };
};

export const GA_CT_TRIAL_START_SUCCESS = (value: number): CPEAnalyticsEvent => {
  return {
    eventCategory: "CT-TRIAL",
    eventAction: "START_SUCCESS",
    eventValue: value
  };
};
