import { Typography } from "@beckersdp/becker-ds";
import styled from "styled-components";

export const Title = styled(Typography.Heading)`
  width: 100%;
  text-align: center;
  margin-bottom: 48px;
  margin-top: 8px;
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 48px;
`;

export const ComplianceOnly = styled.div`
  margin-top: 32px;
  width: 100%;
  text-align: center;
`;
