import { DataSourceItem } from "@beckersdp/becker-ds/dist/select";
import { LicensesType } from "@cpe/models/regulators-license";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegulatorsThunk } from "./thunks";

export interface LicensesStateInterface extends LicensesType {
  regulatorName: string;
}

export interface RegulatorsStateInterface {
  licenses: LicensesStateInterface[] | undefined;
  optionsLicensesNoAICPA: Array<DataSourceItem & { defaultVersionId?: string }>;
  optionsLicenses: DataSourceItem[];
  optionsRegulatorsNoAICPA: DataSourceItem[];
  optionsRegulators: DataSourceItem[];
  selectedLicense: LicensesStateInterface | undefined;
  loading: boolean;
  error: string | null;
}
export const initialState: RegulatorsStateInterface = {
  licenses: [],
  optionsLicensesNoAICPA: [],
  optionsLicenses: [],
  optionsRegulatorsNoAICPA: [],
  optionsRegulators: [],
  selectedLicense: undefined,
  loading: false,
  error: null
};

const sortLicenses = (data: DataSourceItem[]): DataSourceItem[] =>
  data.sort((a, b) => {
    const textA = String(a.label).toUpperCase();
    const textB = String(b.label).toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

export const regulatorsSlice = createSlice({
  name: "regulators",
  initialState: initialState,
  reducers: {
    setSelectedLicense: (state, action: PayloadAction<string>) => {
      state.selectedLicense = state.licenses?.find(license => action.payload === license.name);
    }
  },
  extraReducers: builder => {
    builder.addCase(RegulatorsThunk.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message;
      } else {
        state.licenses = [];
        state.optionsLicenses = [];
        state.optionsLicensesNoAICPA = [];
        state.optionsRegulators = [];
        state.optionsRegulatorsNoAICPA = [];
        const licenses = response?.regulators?.map(regulator => {
          const findActiveLicenses = Boolean(regulator.licenses.find(license => !license?.disableAdd));
          state.optionsRegulators.push({ value: regulator.name, label: regulator.name });
          if (findActiveLicenses) {
            state.optionsRegulatorsNoAICPA.push({ value: regulator.name, label: regulator.name });
          }
          return regulator.licenses.map(license => {
            state.optionsLicenses.push({ value: license.name, label: license.name });
            if (!license?.disableAdd) {
              state.optionsLicensesNoAICPA.push({
                value: license.name,
                label: license.name,
                defaultVersionId: license.defaultVersionId.toString()
              });
            }
            return { ...license, regulatorName: regulator.name };
          });
        });

        state.licenses = licenses?.flat();
        state.optionsLicenses = sortLicenses(state.optionsLicenses);
        state.optionsLicensesNoAICPA = sortLicenses(state.optionsLicensesNoAICPA);
        state.optionsRegulators = sortLicenses(state.optionsRegulators);
        state.optionsRegulatorsNoAICPA = sortLicenses(state.optionsRegulatorsNoAICPA);
      }
      state.loading = false;
    });
    builder.addCase(RegulatorsThunk.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(RegulatorsThunk.pending, state => {
      state.error = "";
      state.loading = true;
    });
  }
});

export const { setSelectedLicense } = regulatorsSlice.actions;
export default regulatorsSlice.reducer;
