import { NavigationLink, permissions } from "@cpe/models/navigation-link";
import React, { FC } from "react";
import { matchPath, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { SessionStorageType } from "../../../../../../constants/storage";
import { GA_COMPLIANCE_NAVIGATION } from "../../../../../../ga/events/compliance/compliance-breakdown";
import { sendGaEvent } from "../../../../../../ga/ga";
import { useStorage } from "../../../../../../hooks";
import { useComplianceSectionContext } from "../../../../../../pages/home/components/compliance-section/compliance-section-provider";
import { availableRoutes } from "../../../../../../router/routes";
import useComplianceDisclaimerContext from "../../../../../compliance-legal-disclaimer/compliance-disclaimer-provider";
import { CompliancePopover } from "../compliance-popover/compliance-popover";
import { Span } from "./styles";

type LinkItemProps = {
  link: NavigationLink;
  "data-qa-label"?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const LinkItem: FC<LinkItemProps> = props => {
  const { children, link, "data-qa-label": qaLabel, ...otherProps } = props;
  const history = useHistory();
  const { sessionStorage } = useStorage();
  const { shouldShowComplianceDisclaimer, openComplianceDisclaimer } = useComplianceDisclaimerContext();
  const complianceCredits = useComplianceSectionContext();

  const isComplianceLink = link.permission === permissions.complianceTracker || link.url === availableRoutes.compliance;
  const showCompliancePopover =
    isComplianceLink && sessionStorage[SessionStorageType.HOME_COMPLIANCE_OLD_TRACKER_POPOVER];

  const matchCalendar = Boolean(useRouteMatch(availableRoutes.calendar) && link.url === "/calendar");
  const matchUsers = Boolean(useRouteMatch(availableRoutes.users) && link.url.split("?")[0] === "/users");
  const matchCompliance = Boolean(
    useRouteMatch(availableRoutes.getCredits) && link.url.split("?")[0] === "/compliance"
  );

  const coursesMatch =
    history?.location?.pathname && matchPath(history.location.pathname, { path: availableRoutes.coursesTab });
  const active =
    history?.location?.pathname === link.url ||
    Boolean(coursesMatch && link.url === availableRoutes.mySearch) ||
    matchCalendar ||
    matchCompliance ||
    matchUsers;

  const hasLicenses = complianceCredits?.complianceCredits && complianceCredits?.complianceCredits?.length > 0;

  if (showCompliancePopover) {
    return (
      <CompliancePopover>
        <div>
          <Span active={active} {...otherProps}>
            <A
              href={link.url}
              data-qa-label={qaLabel}
              onClick={event => {
                if (hasLicenses && shouldShowComplianceDisclaimer()) {
                  event.preventDefault();
                  openComplianceDisclaimer();
                  return;
                }
                sendGaEvent(GA_COMPLIANCE_NAVIGATION);
              }}
            >
              {children}
            </A>
          </Span>
        </div>
      </CompliancePopover>
    );
  }

  return (
    <Span active={active} {...otherProps}>
      <A
        href={link.url}
        data-qa-label={qaLabel}
        target={link.external ? "_blank" : ""}
        onClick={() => isComplianceLink && !link.external && sendGaEvent(GA_COMPLIANCE_NAVIGATION)}
      >
        {children}
      </A>
    </Span>
  );
};

export const A = styled.a`
  box-sizing: border-box;
  padding-top: 4px;
  height: 70px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-family: "Euclid Circular";
  font-weight: 700;
  line-height: 20px;
  color: ${props => props.theme.colors?.brand01.default};
  &.selected {
    color: ${props => props.theme.colors?.brand01.default};
  }
  &:link,
  :visited {
    text-decoration: inherit;
    color: ${props => props.theme.colors?.brand01.default};
  }
`;
