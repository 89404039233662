import { Authentication, LoginRequest } from "@cpe/services/authentication";
import { AccreditationLicenseService } from "@cpe/services/compliance/license";
import { UsersService } from "@cpe/services/users";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SuccessCodeRegex } from "./../../../../../libs/cpe-services/fetch-util";
export const Login = createAsyncThunk(
  "user/login",
  async (resquest: LoginRequest) => await Authentication.login(resquest)
);

export const UserThunk = createAsyncThunk("user/0", async () => await (await UsersService.getCurrentUser()).parsedBody);

export const UserAccreditationLicenseThunk = createAsyncThunk(
  "user/accreditation-licenses",
  async ({ licenseId, userId }: { licenseId: string; userId?: string }, { rejectWithValue }) => {
    const response = await AccreditationLicenseService.get(licenseId, userId);
    if (!response.status.toString().match(SuccessCodeRegex)) {
      return rejectWithValue(await response.parsedBody);
    }
    return (await response).parsedBody;
  }
);

export const enableAccreditationLicenseThunk = createAsyncThunk(
  "user/accreditation-licenses/enable",
  async ({ licenseId, enable }: { licenseId: string; enable: boolean }, { rejectWithValue }) => {
    const response = await (enable
      ? await AccreditationLicenseService.enable(licenseId)
      : await AccreditationLicenseService.disable(licenseId));
    if (!response.status.toString().match(SuccessCodeRegex)) {
      return rejectWithValue(await response.parsedBody);
    }
    return await response.parsedBody;
  }
);
