import styled from "styled-components";

export const IconButton = styled.button`
  background: none;
  color: ${props => props.theme.colors.brand01[400]};
  transition: color 250ms ease-out;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.brand01[700]};
  }
`;
