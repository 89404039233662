import { Authentication } from "@cpe/services/authentication";
import { SuccessCodeRegex } from "@cpe/services/fetch-util";
import { useFormik } from "formik";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { LocalStorageType } from "../../constants/storage";
import { useAppDispatch } from "../../hooks/store";
import { useTranslation } from "../../i18n";
import { availableRoutes } from "../../router/routes";
import { removeStorageItem, storageKeys } from "../../store/storage";

export const useResetPasswordForm = () => {
  const { t } = useTranslation(["resetPassword"]);
  const [serverErrors, setServerErrors] = useState("");
  const location = useLocation();
  const dispatch = useAppDispatch();
  const ResetPasswordSchema = yup.object().shape({
    newPassword: yup
      .string()
      .min(8, t("resetPassword:min"))
      .max(32, t("resetPassword:max"))
      .matches(/^(?=.*[a-z])/, t("resetPassword:oneLowercase"))
      .matches(/^(?=.*[A-Z])/, t("resetPassword:oneUppercase"))
      .matches(/^(?=.*[0-9])/, t("resetPassword:oneNumber"))
      .matches(/^(?=.*[!@#$%^&*\\()_+\-=\\{}\\[\]\\|;:’,.?/`~><])/, t("resetPassword:oneSpecial"))
      .required(t("resetPassword:newPasswordrequired")),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], t("resetPassword:match"))
      .required(t("resetPassword:confirmNewPasswordrequired"))
  });
  const formik = useFormik({
    initialValues: { newPassword: "", confirmNewPassword: "" },
    validationSchema: ResetPasswordSchema,
    onSubmit: async values => {
      const searchParams = new URLSearchParams(location.search);
      const response = await Authentication.setNewPassword(searchParams.get("code") || "", {
        password: values.confirmNewPassword
      });
      if (response?.status.toString().match(SuccessCodeRegex)) {
        const login = await Authentication.login({
          username: searchParams.get("username") || "",
          password: values.confirmNewPassword
        });
        if (login?.status.toString().match(SuccessCodeRegex)) {
          dispatch(
            removeStorageItem({
              key: LocalStorageType.SELECTED_HOME_WIDGET_LICENSE,
              type: storageKeys.localStorage
            })
          );
          dispatch(
            removeStorageItem({
              key: LocalStorageType.SHOW_OLD_TRACKER_TOASTER,
              type: storageKeys.localStorage
            })
          );
          window.location.href = availableRoutes.default;
        } else {
          setServerErrors(t("resetPassword:loginError"));
        }
      } else {
        switch (response?.status) {
          case 400:
            setServerErrors(response?.parsedBody?.message || "");
            break;

          case 401:
            setServerErrors(t("resetPassword:codeExpirationError"));
            break;
        }
      }
    }
  });
  return {
    ...formik,
    serverErrors
  };
};
