import {
  ComplianceCreditsSummary,
  ComplianceLicenseCourseBreakdown,
  LicenseCreditSummary,
  ViewTypeSelectionModel
} from "@cpe/models/credits-license";
import * as FetchUtil from "../../fetch-util";
export class CreditsSummaryService {
  static get(userId?: string, breakdown = false): Promise<FetchUtil.HttpResponse<LicenseCreditSummary>> {
    if (breakdown) {
      return FetchUtil.get(
        `${creditsSummaryApiMatchers.credits}?details=breakdown${userId ? `&userId=${userId}` : ""}`
      );
    }
    return FetchUtil.get(`${creditsSummaryApiMatchers.credits}${userId ? `?userId=${userId}` : ""}`);
  }

  static getLicenseCourseBreakdown(
    accountLicenseId: number,
    breakdownId: string
  ): Promise<FetchUtil.HttpResponse<ComplianceLicenseCourseBreakdown>> {
    return FetchUtil.get(
      creditsSummaryApiMatchers.licenseCourseBreakdown
        .replace(":accountLicenseId", String(accountLicenseId))
        .replace(":breakdownId", breakdownId)
    );
  }

  static getCreditsRounded(accountLicenseId: number): Promise<FetchUtil.HttpResponse<ComplianceCreditsSummary>> {
    return FetchUtil.get(
      `${creditsSummaryApiMatchers.creditsRounded.replace(
        ":accountLicenseId",
        String(accountLicenseId)
      )}?details=breakdown`
    );
  }

  static export(userId?: string) {
    return FetchUtil.downloadFile(`${creditsSummaryApiMatchers.export}${userId ? `?userId=${userId}` : ""}`);
  }

  static viewTypeSelection(body: ViewTypeSelectionModel, accountLicenseId: number, userId?: string) {
    const url = creditsSummaryApiMatchers.viewTypeSelection.replace(":accountLicenseId", String(accountLicenseId));
    return FetchUtil.put(`${url}${userId ? `?userId=${userId}` : ""}`, body);
  }

  static exportForRenewal(accountLicenseId: number, reportPeriodId: string, userId?: string) {
    const url = creditsSummaryApiMatchers.exportForRenewal.replace(":accountLicenseId", String(accountLicenseId));
    return FetchUtil.downloadFile(`${url}?rpId=${reportPeriodId}${userId ? `&userId=${userId}` : ""}`);
  }
}

export const creditsSummaryApiMatchers = {
  credits: "/api/v1/compliance/credits",
  creditsRounded: "/api/v1/compliance/credits/:accountLicenseId",
  licenseCourseBreakdown: "/api/v1/compliance/credits/:accountLicenseId/breakdown/:breakdownId",
  export: "/api/v1/compliance/credits/download",
  exportForRenewal: "/api/v1/compliance/licenses/:accountLicenseId/download",
  viewTypeSelection: "/api/v1/compliance/licenses/:accountLicenseId/viewTypeSelection"
};
