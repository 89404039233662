import { CartItemModel } from "@cpe/models/cart";
import { createSlice } from "@reduxjs/toolkit";
import { Cart, CartAdd, CartRemove } from "./thunks";

export interface CartState {
  items: CartItemModel[];
  subTotalPrice: number;
  isOpen: boolean;
  loading: boolean;
  isFirstLoad: boolean;
  error: string | null;
}
export const initialState: CartState = {
  items: [],
  subTotalPrice: 0,
  isOpen: false,
  loading: false,
  isFirstLoad: true,
  error: null
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    setMiniCartOpenStatus: state => {
      state.isOpen = !state.isOpen;
    }
  },
  extraReducers: builder => {
    builder.addCase(Cart.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message;
      } else {
        const refreshedItems = (action.payload?.items as CartItemModel[]) || [];

        if (state.items.length === 0 && refreshedItems.length === 1 && !state.isFirstLoad) {
          state.isOpen = true;
        }
        state.items = (action.payload?.items as CartItemModel[]) || [];
        state.subTotalPrice = (action.payload?.subTotalPrice as number) || 0;
        state.error = "";
      }
      state.isFirstLoad = false;
      state.loading = false;
    });
    builder.addCase(Cart.rejected, state => {
      state.loading = false;
      state.items = [];
      state.subTotalPrice = 0;
      state.error = "Something is wrong.";
    });
    builder.addCase(Cart.pending, state => {
      state.error = "";
      state.loading = true;
    });
    builder.addCase(CartAdd.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message;
      }
      state.loading = false;
    });
    builder.addCase(CartAdd.rejected, state => {
      state.loading = false;
      state.error = "error";
    });
    builder.addCase(CartAdd.pending, state => {
      state.error = "";
      state.loading = true;
    });
    builder.addCase(CartRemove.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message;
      }
      state.loading = false;
    });
    builder.addCase(CartRemove.rejected, state => {
      state.loading = false;
      state.error = "error";
    });
    builder.addCase(CartRemove.pending, state => {
      state.error = "";
      state.loading = true;
    });
  }
});

export const { setMiniCartOpenStatus } = cartSlice.actions;
export default cartSlice.reducer;
