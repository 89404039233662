import { defaultTheme } from "@beckersdp/becker-ds";
import { BreakpointName } from "@beckersdp/becker-ds/dist/grid/config";

type MediaQueriesByBreakpoint = Record<
  keyof typeof defaultTheme.breakpoints,
  {
    max: string;
    min: string;
    maxMin: string;
  }
>;

const MAX_SCREEN_WIDTH = 90000;
const BASE_MEDIA_QUERY_PART = `@media only screen`;

export const media = Object.keys(defaultTheme.breakpoints)
  .sort((k1: BreakpointName, k2: BreakpointName) => defaultTheme.breakpoints[k1] - defaultTheme.breakpoints[k2])
  .reduce((acc, cur: BreakpointName, idx, arr: BreakpointName[]) => {
    const screenWidth = defaultTheme.breakpoints[cur];
    const nextScreenWidth = defaultTheme.breakpoints[arr[idx + 1]];
    const minPart = `(min-width: ${idx === 0 ? 0 : screenWidth}px)`;
    const maxPart = `(max-width: ${idx === arr.length - 1 ? MAX_SCREEN_WIDTH : nextScreenWidth - 1}px)`;
    const min = [BASE_MEDIA_QUERY_PART, minPart].join(" and ");
    const max = [BASE_MEDIA_QUERY_PART, maxPart].join(" and ");
    const maxMin = [BASE_MEDIA_QUERY_PART, minPart, maxPart].join(" and ");

    acc[cur] = {
      min,
      max,
      maxMin
    };

    return acc;
  }, {} as MediaQueriesByBreakpoint);
