import React from "react";
import ReactDOM from "react-dom/client";
import { StyleSheetManager } from "styled-components";
import { CPEWrapper } from "./cpe-wrapper";
import { FooterWrapper } from "./footer/index";

class CPEFooter extends HTMLElement {
  private readonly styleHost: HTMLElement;
  private readonly mountPoint: HTMLElement;
  constructor() {
    super();
    this.mountPoint = document.createElement("div");
  }
  connectedCallback() {
    ReactDOM.createRoot(this).render(
      <StyleSheetManager disableCSSOMInjection={true}>
        <CPEWrapper withAuth={true}>
          <FooterWrapper />
        </CPEWrapper>
      </StyleSheetManager>
    );
  }
  disconnectedCallback() {
    this.shadowRoot?.removeChild(this.mountPoint);
    this.shadowRoot?.removeChild(this.styleHost);
  }
}
customElements.define("cpe-footer", CPEFooter);
