import { AvailableUserSettingsKeys, UserSettingsType } from "@cpe/services/user-settings";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserSettingsThunk, UserSettingsUpdateThunk } from "./thunks";

interface UserSettingsState {
  keys: UserSettingsType;
  loading: boolean;
}

export const initialState: UserSettingsState = {
  keys: {},
  loading: false
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setDeleteUserSetting: (state, action: PayloadAction<{ key: AvailableUserSettingsKeys }>) => {
      const actualKeys = { ...state.keys };
      delete actualKeys[action.payload.key];
      state.keys = { ...actualKeys };
    }
  },
  extraReducers: builder => {
    builder.addCase(UserSettingsThunk.fulfilled, (state, action) => {
      const settings = action.payload;
      if (!settings) {
        state.loading = false;
        return;
      }
      state.keys = { ...settings };
      state.loading = false;
    });
    builder.addCase(UserSettingsThunk.rejected, state => {
      state.loading = false;
    });
    builder.addCase(UserSettingsThunk.pending, state => {
      state.loading = true;
    });
    builder.addCase(UserSettingsUpdateThunk.fulfilled, (state, action) => {
      const settings = action.payload;
      if (!settings) {
        state.loading = false;
        return;
      }
      state.keys = { ...settings };
      state.loading = false;
    });
    builder.addCase(UserSettingsUpdateThunk.rejected, state => {
      state.loading = false;
    });
    builder.addCase(UserSettingsUpdateThunk.pending, state => {
      state.loading = true;
    });
  }
});

export const { setDeleteUserSetting } = userSlice.actions;
export default userSlice.reducer;
