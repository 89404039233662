import { AvailableFeaturesFlags } from "@cpe/models/feature-flag";
import { User } from "@cpe/models/user";
import React, { FC, useEffect } from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import { useFeatureFlags } from "../components/feature-flag/use-feature-flags";
import { UA_gaSetGlobalDimensions, gaSetGlobalDimensions, isGa4 } from "../ga/ga";
import { useNavigation, useUser } from "../hooks";
import { useAppDispatch } from "../store";
import { NavigationThunk, NavigationThunkV2 } from "../store/navigation/thunks";
import { UserThunk } from "../store/user/thunks";
import { availableRoutes } from "./routes";
import { PageId } from "./types";

type Props = {
  component: any;
  featureFlag?: AvailableFeaturesFlags | AvailableFeaturesFlags[];
  pageId: PageId;
  updateStore?: boolean; // NOTE: this is only to avoid exported components to make multiple requests to the user info and navigation links endpoints
} & RouteProps;

export const PrivateRoute: FC<Props> = ({ component: Component, featureFlag, pageId, updateStore = true, ...rest }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { user } = useUser();
  const { main, loading: isLoadingNav } = useNavigation();
  const { isLoading, isFFActive } = useFeatureFlags();

  const getUserInfo = async () => {
    const user = (await (await dispatch(UserThunk())).payload) as User;
    if (isFFActive("REORG_ADMIN_NAVIGATION")) {
      if (main.length === 0 && !isLoadingNav) {
        await dispatch(NavigationThunkV2());
      }
    } else {
      if (main.length === 0 && !isLoadingNav) {
        await dispatch(NavigationThunk());
      }
    }

    if (user?.username || user?.email) {
      if (isGa4) {
        gaSetGlobalDimensions(user.studentHid, pageId);
      } else {
        UA_gaSetGlobalDimensions(user.studentHid, pageId);
      }

      history.replace({
        pathname: history.location.pathname || availableRoutes.default,
        search: history.location.search
      });
    } else {
      history.replace(`${availableRoutes.generalSignIn}?redirectTo=${history.location.pathname}`);
    }
  };
  useEffect(() => {
    if (!user.email && updateStore && isLoading === false && main.length === 0) {
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.email, isLoading]);

  if (rest.location?.search?.includes("tid=")) {
    return (
      <Route
        {...rest}
        render={props => {
          return user.isAuthenticated ? (
            <Redirect to={`/courses/provider?provider=${props.location.search.split("tid=")[1]}`} />
          ) : null;
        }}
      />
    );
  }

  if (featureFlag && isLoading === false && !isFFActive(featureFlag)) {
    return <Redirect to={availableRoutes.default} />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        return user.isAuthenticated && isLoading === false ? <Component {...props} /> : null;
      }}
    />
  );
};
