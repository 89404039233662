export const formatedPrice = (price?: number, decimals = 2, forceDecimals = false) => {
  if (!price) {
    return "";
  }
  if (forceDecimals) {
    return price.toFixed(decimals);
  }
  const hasDecimals = price.toString().indexOf(".");
  return hasDecimals ? price.toFixed(decimals) : price;
};
