import { Button, Card, Icon, Typography } from "@beckersdp/becker-ds";
import React, { FC } from "react";
import styled, { useTheme } from "styled-components";
import { ProductCardQALabels } from "../../../../page-objects/components/upgrade-modal/qa-labels";

type Type = "Normal" | "Alternative";
interface Props {
  title: string;
  price: string;
  type?: Type;
  action: {
    name: string;
    onAction: () => void;
  };
  advantages: string[];
}

export const PromotionCard: FC<Props> = props => {
  const theme = useTheme();
  const { type = "Normal", action, advantages, title, price } = props;
  return (
    <>
      <PromoCardContainer data-qa-label={ProductCardQALabels.card}>
        <AlternativePromoCard
          color={theme.colors.brand01.default}
          $type={type}
          cardStyle={type ? "long-shadow" : undefined}
        >
          <Header>
            <Product level="3" $type={type} data-qa-label={ProductCardQALabels.productName}>
              {title}
            </Product>
            <Price level="1" $type={type} weight="bold" data-qa-label={ProductCardQALabels.productPrice}>
              {price}
            </Price>
          </Header>
          <AdvantagesList>
            {advantages.map((advantage, index) => (
              <AdvantageItem key={index}>
                <AdvantageListIcon $type={type} name="check-circle-2-full" />
                <Typography.Text level="2" color={type === "Normal" ? "primary-inverted" : undefined}>
                  {advantage}
                </Typography.Text>
              </AdvantageItem>
            ))}
          </AdvantagesList>
          <UpgradeButton
            block
            type={type === "Normal" ? "primary" : "outline"}
            onClick={action.onAction}
            data-qa-label={ProductCardQALabels.productAction}
          >
            {action.name}
          </UpgradeButton>
        </AlternativePromoCard>
      </PromoCardContainer>
    </>
  );
};

const UpgradeButton = styled(Button)`
  margin-top: 10px;
`;

const AdvantagesList = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;

const AdvantageItem = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const AdvantageListIcon = styled(Icon)<{ $type: Type }>`
  width: 24px;
  flex-shrink: 0;
  color: ${props => (props.$type === "Normal" ? props.theme.colors.support01[600] : props.theme.colors.support01[500])};
  margin-right: 16px;
`;

const PromoCardContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 405px;
`;

const Product = styled(Typography.Heading)<{ $type: Type }>`
  color: ${props => (props.$type === "Normal" ? props.theme.colors.white : "inherit")};
  margin-right: 24px;
`;

const Price = styled(Typography.Text)<{ $type: Type }>`
  color: ${props => (props.$type === "Normal" ? props.theme.colors.white : "inherit")};
  flex-shrink: 0;
`;

const PromoCard = styled(Card)`
  border-radius: 30px;
  width: 100%;
  max-width: 345px;
  min-width: 288px;
  padding: 32px;
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 26px;
`;

const AlternativePromoCard = styled(PromoCard)<{ $type: Type }>`
  display: flex;
  flex-flow: column;
  background-color: ${props => (props.$type === "Normal" ? props.theme.colors.brand01[800] : "inherit")};
  ${AdvantagesList} {
    ${Typography.Text} {
      line-height: ${props => (props.$type === "Normal" ? "20px" : "inherit")};
    }
  }
`;
