export const UpgradeModalQALabels = {
  title: "upgrade-modal-title",
  container: "upgrade-modal"
};

export const ProductCardQALabels = {
  productName: "promotion-name",
  productPrice: "promotion-price",
  productAction: "promotion-action",
  card: "promotion-card"
};
