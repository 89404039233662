import { ga as UA_ga, initialize as UA_initialize } from "react-ga";
import ReactGA from "react-ga4";

const GA_KEY = window?.__config__?.gaKey;
export const isGa4 = GA_KEY?.startsWith("G-");

const globalCustomDimensions: GACustomDimensions = {};
const UA_globalCustomDimensions: UA_GACustomDimensions = {};

if (isGa4) {
  ReactGA.initialize(GA_KEY as any, {
    testMode: process.env.NODE_ENV === "development"
  });
} else {
  UA_initialize(GA_KEY as any, {
    debug: process.env.NODE_ENV === "development"
  });
}

/* Hotjar build events logic => FEATURE-PAGE-COMPONENT-ACTION_ACTION */
export interface DefaultGaModel {
  eventCategory: string;
  eventValue?: number;
  eventAction?: string;
  nonInteraction?: boolean;
  transport?: "beacon" | "xhr" | "image";
}

export interface DefaultGAEventProps {
  eventCategory: string;
  eventAction: string;
  eventValue?: number;
}

export interface UA_DefaultGAEventProps {
  eventCategory: string;
  eventAction: string;
  dimension37?: string;
  dimension38?: boolean | string;
  eventValue?: number;
}

export const sendGaEvent = <T extends DefaultGAEventProps>(props: T) => {
  ReactGA.ga(...["send", { hitType: "event", ...props }]);
};

export const UA_sendGaEvent = <T extends UA_DefaultGAEventProps>(props: T) => {
  UA_ga(...["send", "event", props]);
};

export const sendGACustomEvent = (event: CPEAnalyticsEvent) => {
  const { eventCategory, eventValue, eventAction, nonInteraction, transport, ...customDimensions } = event;
  const gaEvent: GACustomEvent = { eventCategory, eventValue, eventAction, nonInteraction, transport };
  Object.entries(customDimensions).forEach(([CPEDimensionName, value]) => {
    if (value) {
      const gaCustomDimension = customDimensionsMap[
        CPEDimensionName as keyof CPECustomDimensions
      ] as keyof GACustomDimensions;
      gaEvent[gaCustomDimension] = value;
    }
  });
  ReactGA.ga(...["send", { hitType: "event", ...gaEvent, ...globalCustomDimensions }]);
};

export const UA_sendGACustomEvent = (event: UA_CPEAnalyticsEvent) => {
  const { eventCategory, eventValue, eventAction, nonInteraction, transport, ...customDimensions } = event;
  const gaEvent: UA_GACustomEvent = { eventCategory, eventValue, eventAction, nonInteraction, transport };
  Object.entries(customDimensions).forEach(([CPEDimensionName, value]) => {
    if (value) {
      const gaCustomDimension = UA_customDimensionsMap[
        CPEDimensionName as keyof UA_CPECustomDimensions
      ] as keyof UA_GACustomDimensions;
      gaEvent[gaCustomDimension] = value as any;
    }
  });
  UA_ga(...["send", "event", { ...gaEvent, ...UA_globalCustomDimensions }]);
};

export const sendGaPageView = (pathname: string) => {
  if (!isGa4) {
    UA_ga("send", "pageview", pathname);
  }
};

export const gaSetGlobalDimensions = (studentHid: string, page: string) => {
  if (customDimensionsMap["studentHid"] && customDimensionsMap["page"]) {
    globalCustomDimensions[customDimensionsMap["studentHid"]] = studentHid;
    globalCustomDimensions[customDimensionsMap["page"]] = page;
  }
};

export const UA_gaSetGlobalDimensions = (studentHid: string, page: string) => {
  if (UA_customDimensionsMap["studentHid"] && UA_customDimensionsMap["page"]) {
    UA_globalCustomDimensions[UA_customDimensionsMap["studentHid"]] = studentHid;
    UA_globalCustomDimensions[UA_customDimensionsMap["page"]] = page;
  }
};

export type CPEAnalyticsEvent = {
  eventCategory: string;
  eventValue?: number;
  eventAction?: string;
  nonInteraction?: boolean;
  transport?: "beacon" | "xhr" | "image";
} & CPECustomDimensions;

export type UA_CPEAnalyticsEvent = {
  eventCategory: string;
  eventValue?: number;
  eventAction?: string;
  nonInteraction?: boolean;
  transport?: "beacon" | "xhr" | "image";
} & UA_CPECustomDimensions;

interface CPECustomDimensions {
  studentHid?: string;
  context?: string;
  step?: string;
  page?: string;
}
type UA_CPECustomDimensions = CPECustomDimensions & { player_entrance?: string; recommendation?: boolean | string };

type GACustomEvent = {
  eventCategory: string;
  eventValue?: number;
  eventAction?: string;
  nonInteraction?: boolean;
  transport?: "beacon" | "xhr" | "image";
} & GACustomDimensions;

type UA_GACustomEvent = {
  eventCategory: string;
  eventValue?: number;
  eventAction?: string;
  nonInteraction?: boolean;
  transport?: "beacon" | "xhr" | "image";
} & UA_GACustomDimensions;

interface GACustomDimensions {
  lms_student_hid?: string;
  lms_context?: string;
  lms_step?: string;
  page_title?: string;
}
interface UA_GACustomDimensions {
  dimension37?: string;
  dimension38?: boolean | string;
  dimension41?: string;
  dimension42?: string;
  dimension43?: string;
  dimension40?: string;
}

const customDimensionsMap: { [key in keyof CPECustomDimensions]: keyof GACustomDimensions } = {
  page: "page_title",
  studentHid: "lms_student_hid",
  context: "lms_context",
  step: "lms_step"
};

const UA_customDimensionsMap: { [key in keyof UA_CPECustomDimensions]: keyof UA_GACustomDimensions } = {
  page: "dimension40",
  studentHid: "dimension41",
  context: "dimension42",
  step: "dimension43",
  player_entrance: "dimension37",
  recommendation: "dimension38"
};
