import { Icon, Typography } from "@beckersdp/becker-ds";
import { NavigationElement, NavigationItem } from "@cpe/models/navigation-link";
import React, { FC } from "react";
import styled from "styled-components";
import { useDeviceBreakpoint } from "../../../../../../../../hooks/use-device-breakpoint";
import { PageQALabels } from "../../../../../../../../page-objects/components/page/qa-labels";
interface Props {
  links: NavigationElement;
}
export const SupportLinks: FC<Props> = props => {
  const { links } = props;
  const helpCenterLink = links[0] as NavigationItem;
  const { isDesktopSD } = useDeviceBreakpoint();
  const handleClick = () => {
    if (helpCenterLink.external) {
      window.open(helpCenterLink.url, "_blank");
    }
  };
  return (
    <LI role="listitem" tabIndex={0} data-qa-label={PageQALabels.helpCenterLink} onClick={handleClick}>
      <CustomIcon name={helpCenterLink.iconKey || ""} />
      {isDesktopSD ? null : <Typography.Text level="2">{helpCenterLink.label}</Typography.Text>}
    </LI>
  );
};

const CustomIcon = styled(Icon)`
  height: 31px;
  width: 31px;
  color: ${props => props.theme.colors.brand01[600]};
  flex-grow: 0;
  flex-shrink: 0;
`;

const LI = styled.div`
  box-sizing: border-box;
  height: 62px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding-top: 12px;
  margin-bottom: 12px;
  gap: 8px;
  color: ${props => props.theme.colors.brand01[700]};
  ${Typography.Text} {
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
  }

  & :hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
  &&:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.brand01.default};
    ${Typography.Text} {
      color: ${props => props.theme.colors.brand01.default};
    }
    ${CustomIcon} {
      color: ${props => props.theme.colors.brand01.default};
    }
  }
  &.active {
    color: ${props => props.theme.colors.brand01.default};
  }
`;
