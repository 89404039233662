import React from "react";
import { useTranslation } from "../../../i18n";
import MiniCartTopRow from "./mini-cart-top-row";

const EmptyMiniCart: React.FC = () => {
  const { t } = useTranslation(["cart"]);

  return <MiniCartTopRow isEmptyCart title={t("cart:empty_cart")} />;
};

export default EmptyMiniCart;
