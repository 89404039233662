import { NavigationLinksService } from "@cpe/services/ux/navigation";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const NavigationThunk = createAsyncThunk(
  "ux/navigation",
  async () => await (await NavigationLinksService.get())?.parsedBody
);

export const NavigationThunkV2 = createAsyncThunk(
  "ux/navigation_v2",
  async () => await (await NavigationLinksService.getLinks())?.parsedBody
);
