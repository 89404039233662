import { CPEAnalyticsEvent, UA_CPEAnalyticsEvent } from "../ga";

export interface GaPlayerEntranceProps {
  eventLabel?: string;
  eventValue?: number;
}

export interface UA_GaPlayerEntranceProps {
  player_entrance?: string;
  recommendation?: boolean | string;
}

export const GA_PLAYER_ENTRANCE = (props: GaPlayerEntranceProps): CPEAnalyticsEvent => {
  return {
    eventCategory: "PLAYER",
    eventAction: "LAUNCH",
    ...props
  };
};

export const UA_GA_PLAYER_ENTRANCE = (props: UA_GaPlayerEntranceProps): UA_CPEAnalyticsEvent => {
  return {
    eventCategory: "Player",
    eventAction: "Launch",
    ...props
  };
};
