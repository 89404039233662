import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const featureFlagsApi = createApi({
  reducerPath: "featureFlags",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1"
  }),
  endpoints: builder => ({
    getFeatureFlags: builder.query({
      query: () => "/admin/settings/toggles"
    })
  })
});

// Export hooks for usage in functional components
export const { useGetFeatureFlagsQuery } = featureFlagsApi;
