import { Course } from "@cpe/models/course";
import { CoursesService } from "@cpe/services/courses";
import { ErrorResponse } from "@cpe/services/fetch-util";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const SimilarCourses = createAsyncThunk<(ErrorResponse & Course[]) | undefined, string | undefined, any>(
  "courses/get/similar",
  async (productId: string) => (await CoursesService.similarCourses(productId)).parsedBody
);

export const OthersAlsoPlayedCourses = createAsyncThunk<
  (ErrorResponse & Course[]) | undefined,
  string | undefined,
  any
>(
  "courses/get/others",
  async (productId: string) => (await CoursesService.othersAlsoPlayedCourses(productId)).parsedBody
);
