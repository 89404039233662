import { Dropdown, Icon } from "@beckersdp/becker-ds";
import styled from "styled-components";

export const CustomDropdownMenu = styled(Dropdown.Menu)`
  box-shadow: none;
  top: 25px !important;
  left: -10px !important;
  padding-top: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const CustomOption = styled(Dropdown.Option)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.colors?.brand01.default};
  padding: 0 10px 20px 10px;
  background-color: ${props => props.theme.colors.white};
  &:hover {
    text-decoration: underline;
    background-color: ${props => props.theme.colors.white};
  }
`;

export const NavOption = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuText = styled.span`
  max-width: 105px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors?.brand01.default};
`;

export const Span = styled.span<{ active?: boolean }>`
  height: 66px;
  white-space: nowrap;
  display: inline-flex;
  align-items: baseline;
  padding: 0 15px;
  border-bottom: 4px solid ${props => (props.active ? props => props.theme.colors?.brand01.default : "transparent")};
  &:not(:first-child) {
    margin-left: -1px;
  }
  &:hover {
    border-bottom: 4px solid ${props => props.theme.colors?.brand01[700]};
  }
`;

export const DownChevronIcon = styled(Icon).attrs({
  name: "chevron-down"
})`
  width: 18px;
`;
