import { Icon, Typography } from "@beckersdp/becker-ds";
import React from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../../hooks/store";
import { MiniCartQALabels } from "../../../page-objects/components/mini-cart/qa-labels";
import { setMiniCartOpenStatus } from "../../../store/cart";
import { IconButton } from "../../icon-button";

interface Props {
  title: string;
  isEmptyCart?: boolean;
}

const MiniCartTopRow: React.FC<Props> = ({ title, isEmptyCart }) => {
  const dispatch = useAppDispatch();
  const toogleCart = () => dispatch(setMiniCartOpenStatus());

  return (
    <TopRow>
      {!isEmptyCart ? (
        <Title level="1" weight="bold" color="primary-inverted" data-qa-label={MiniCartQALabels.topRowTitle}>
          {title}
        </Title>
      ) : (
        <Title level="2" weight="medium" color="primary-inverted" data-qa-label={MiniCartQALabels.topRowTitle}>
          {title}
        </Title>
      )}
      <StyledIconButton aria-label="close-mini-cart" onClick={toogleCart} data-qa-label={MiniCartQALabels.closeButton}>
        <Icon name="x" />
      </StyledIconButton>
    </TopRow>
  );
};

const TopRow = styled.div`
  display: flex;
`;

const Title = styled(Typography.Text)`
  flex: 1;
`;

export const StyledIconButton = styled(IconButton)`
  flex: 0;
  color: ${props => props.theme.colors.white};

  > svg {
    height: 11px;
    width: 11px;
  }
`;

export default MiniCartTopRow;
