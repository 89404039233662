import { Dialog, Modal } from "@beckersdp/becker-ds";
import React from "react";
import { useEula } from ".";
import { useTranslation } from "../i18n";

export const useEulaModal = () => {
  const { t } = useTranslation(["EulaModal"]);
  const { eula } = useEula();

  const handleEulaClick = async () => {
    Dialog.dismiss({
      maxWidth: 930,
      title: eula?.subject,
      okText: t("EulaModal:close"),
      extraOkProps: { size: "small", type: "onboarding" },
      content: <Modal.BodyText dangerouslySetInnerHTML={{ __html: eula?.body || "" }} />
    });
  };

  return { handleEulaClick };
};
