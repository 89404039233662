import { ComplianceUsersLicenses, ComplianceUserStatus } from "@cpe/services/compliance/users";
import { createSlice } from "@reduxjs/toolkit";
import { ComplianceUsersCoursesSearch } from "./thunks";

export const keys = {
  all: "ALL",
  incomplete: "INCOMPLETE",
  completed: "COMPLETED"
};

export interface FilteredUsersModel {
  key: string;
  firstName: string;
  lastName: string;
  userName: string;
  b2bClient: string[];
  licenseName: string;
  reportingDate: string;
  creditsRemaining: number;
  creditsRequired: number;
  creditsEarned: number;
  isClientAdmin?: boolean;
  isCurrentUser?: boolean;
  status: keyof typeof ComplianceUserStatus;
  userLicenses: ComplianceUsersLicenses[];
}

export interface ComplianceUsersState {
  filteredUsers: FilteredUsersModel[];
  total: number | undefined;
  loading: boolean;
  error: string | undefined;
}
export const initialState: ComplianceUsersState = {
  filteredUsers: [],
  total: undefined,
  loading: false,
  error: undefined
};

const sumCredits = (licenses: ComplianceUsersLicenses[], type: "Remaining" | "Required" | "Earned") => {
  let sum = 0;
  licenses?.map(license => {
    if (type === "Remaining") {
      sum = sum + license.credits.remaining;
    } else if (type === "Required") {
      sum = sum + license.credits.required;
    } else {
      sum = sum + license.credits.hours;
    }
  });
  return Number(sum !== 0 ? sum.toFixed(1) : 0);
};

export const complianceUsersSlice = createSlice({
  name: "complianceUsers",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ComplianceUsersCoursesSearch.fulfilled, (state, action) => {
      const response = action.payload;
      state.filteredUsers = [];
      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
      } else {
        response?.users.forEach(user => {
          state.filteredUsers.push({
            key: user.id.toString(),
            firstName: user.firstName,
            lastName: user.lastName,
            userName: user.username,
            isClientAdmin: user.isClientAdmin,
            isCurrentUser: user.isCurrentUser,
            b2bClient: user.tenantNames,
            licenseName: user.licenses?.[0]?.name,
            reportingDate: user.licenses?.[0]?.reportingDate,
            creditsEarned: sumCredits(user?.licenses, "Earned"),
            creditsRemaining: sumCredits(user?.licenses, "Remaining"),
            creditsRequired: sumCredits(user?.licenses, "Required"),
            status: user.globalStatus,
            userLicenses: user?.licenses
          });
        });
        state.total = response?.total;
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(ComplianceUsersCoursesSearch.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(ComplianceUsersCoursesSearch.pending, state => {
      state.error = "";
      state.loading = true;
    });
  }
});

export default complianceUsersSlice.reducer;
