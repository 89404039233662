import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isNull } from "lodash/fp";
import isEmpty from "lodash/isEmpty";
import { LocalStorageType, SessionStorageType } from "../../constants/storage";

export const storageKeys: { localStorage: WindowStorageType; sessionStorage: WindowStorageType } = {
  sessionStorage: "sessionStorage",
  localStorage: "localStorage"
};

export type WindowStorageType = "sessionStorage" | "localStorage";
type SessionStorageTypeKeys = { [key in SessionStorageType]?: any };
type LocalStorageTypeKeys = { [key in LocalStorageType]?: any };

interface StorageState {
  sessionStorage: SessionStorageTypeKeys;
  localStorage: LocalStorageTypeKeys;
}

const setInitialStorage = (sessionStorage: WindowStorageType) => {
  const storage = sessionStorage === storageKeys.sessionStorage ? window.sessionStorage : window.localStorage;
  const initialStorage: any = {};
  Object.keys(storage).forEach((key: LocalStorageType | SessionStorageType) => {
    const storageItem = storage.getItem(key);
    const isObject = storageItem?.startsWith("{") && storageItem?.endsWith("}") && storageItem.includes(":");
    const item = isEmpty(storageItem) || isNull(storageItem) ? "{}" : isObject ? JSON.parse(storageItem) : storageItem;
    initialStorage[key] = item;
  });
  return initialStorage;
};

export const initialState: StorageState = {
  sessionStorage: setInitialStorage(storageKeys.sessionStorage),
  localStorage: setInitialStorage(storageKeys.localStorage)
};

export const storageSlice = createSlice({
  name: "storage",
  initialState: initialState,
  reducers: {
    setStorageItem: (
      state,
      action: PayloadAction<{ key: LocalStorageType | SessionStorageType; value: any; type: WindowStorageType }>
    ) => {
      if (action.payload.type === storageKeys.sessionStorage) {
        state.sessionStorage = { ...state.sessionStorage, [action.payload.key]: action.payload.value };
        window.sessionStorage.setItem(action.payload.key, JSON.stringify(action.payload.value));
      } else {
        state.localStorage = { ...state.localStorage, [action.payload.key]: action.payload.value };
        window.localStorage.setItem(action.payload.key, JSON.stringify(action.payload.value));
      }
    },
    removeStorageItem: (
      state,
      action: PayloadAction<{ key: LocalStorageType | SessionStorageType; type: WindowStorageType }>
    ) => {
      if (action.payload.type === storageKeys.sessionStorage) {
        delete state.sessionStorage?.[action.payload.key as SessionStorageType];
        window.sessionStorage.removeItem(action.payload.key);
      } else {
        delete state.localStorage?.[action.payload.key as LocalStorageType];
        window.localStorage.removeItem(action.payload.key);
      }
    },
    clearStorage: (state, action: PayloadAction<{ type: WindowStorageType }>) => {
      if (action.payload.type === storageKeys.sessionStorage) {
        state.sessionStorage = {};
      } else {
        state.localStorage = {};
      }
    }
  }
});

export const { setStorageItem, removeStorageItem, clearStorage } = storageSlice.actions;
export default storageSlice.reducer;
