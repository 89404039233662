export const MiniCartQALabels = {
  toggledWrapper: "mini-cart-toggled-wrapper",
  toggleButton: "mini-cart-toggle-button",
  topRowTitle: "mini-cart-top-row-title",
  closeButton: "mini-cart-close-button",
  miniCartItem: "mini-cart-item",
  checkoutButton: "mini-cart-checkout-button",
  viewCartButton: "mini-cart-biew-cart-button",
  miniCartItemsLength: "mini-cart-items-length"
};
