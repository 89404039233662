import { Icon, Typography } from "@beckersdp/becker-ds";
import React, { Dispatch, FC, SetStateAction } from "react";
import styled from "styled-components";
import { useCart } from "../../../../../../hooks";
import { MiniCartQALabels } from "../../../../../../page-objects/components/mini-cart/qa-labels";
import { useAppDispatch } from "../../../../../../store";
import { setMiniCartOpenStatus } from "../../../../../../store/cart";
import { IconButton } from "../../../../../icon-button";

interface Props {
  setReferenceElement?: Dispatch<SetStateAction<HTMLElement | null>>;
  isMobile?: boolean;
  numberItemsInCart: number;
}

const MiniCartButton: FC<Props> = ({ setReferenceElement, isMobile, numberItemsInCart }) => {
  const dispatch = useAppDispatch();
  const cart = useCart();

  const toogleCart = () => dispatch(setMiniCartOpenStatus());

  return (
    <Wrapper ref={setReferenceElement} mobile={isMobile?.toString()}>
      <StyledIconButton
        aria-label="toogle-mini-cart"
        data-qa-label={MiniCartQALabels.toggleButton}
        data-loading={cart.loading}
        onClick={toogleCart}
      >
        {numberItemsInCart > 0 && (
          <NumberItemsTypography
            level="3"
            weight="bold"
            color="primary-inverted"
            wide={numberItemsInCart?.toString()?.length}
            data-qa-label={MiniCartQALabels.miniCartItemsLength}
          >
            {numberItemsInCart}
          </NumberItemsTypography>
        )}
        <Icon name="cart" width="1.75rem" height="1.625rem" />
      </StyledIconButton>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ mobile?: string }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props?.mobile === "true" && "1rem"};
`;

const StyledIconButton = styled(IconButton)`
  position: relative;
  color: ${props => props.theme.colors.brand01[800]};
`;

const NumberItemsTypography = styled(Typography.Text)<{ wide: number }>`
  position: absolute;
  top: -0.25rem; /* 4px */
  right: -0.25rem; /* 4px */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${props => (props.wide > 2 ? "1.50rem" : "1.25rem")}; /* 24px / 20px */
  height: ${props => (props.wide > 2 ? "1.50rem" : "1.25rem")}; /* 24px / 20px */
  background: ${props => props.theme.colors.brand03.default};
`;

export default MiniCartButton;
