import { CartItemModel, CartModel } from "@cpe/models/cart";
import * as FetchUtil from "../fetch-util";

interface NavigationLinksResponse {
  redirectUrl: string;
}

export class CartService {
  static add(productIds: string[]): Promise<FetchUtil.HttpResponse<NavigationLinksResponse>> {
    return this.checkout(productIds);
  }
  static checkout(productIds: string[]): Promise<FetchUtil.HttpResponse<NavigationLinksResponse>> {
    return FetchUtil.post(cartServiceRoutes.checkout, { productIds });
  }
  static addToCart(cartItem: CartItemModel): Promise<FetchUtil.HttpResponse<FetchUtil.ErrorResponse & CartModel>> {
    return FetchUtil.post(`/api/v1/store/cart/${cartItem.productId}`, {});
  }
  static getCart(): Promise<FetchUtil.HttpResponse<FetchUtil.ErrorResponse & CartModel>> {
    return FetchUtil.get(`/api/v1/store/cart`);
  }
  static remove(productId: string): Promise<FetchUtil.HttpResponse<FetchUtil.ErrorResponse & CartModel>> {
    return FetchUtil.Delete(`/api/v1/store/cart/${productId}`, {});
  }
}

export const cartServiceRoutes = {
  checkout: "/api/v1/store/cart/checkout"
};
