import * as FetchUtil from "../fetch-util";

export interface LoginRequest {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export interface RecoverRequest {
  username: string;
}
export interface SetNewPasswordRequest {
  password: string;
}

export interface RecoverResponse {
  redirectUrl?: string;
}
export interface ResetResponse {
  message?: string;
}
export interface LoginResponse {
  data?: UpdatePasswordData;
}

interface UpdatePasswordData {
  updatePassword: {
    token: string;
  };
}

export class Authentication {
  static login(request: LoginRequest): Promise<FetchUtil.HttpResponse<LoginResponse>> {
    return FetchUtil.post(authenticationApiMatchers.login, request);
  }
  static accountRecover(request: RecoverRequest): Promise<FetchUtil.HttpResponse<RecoverResponse>> {
    return FetchUtil.post(authenticationApiMatchers.accountRecover, request);
  }
  static setNewPassword(token: string, request: SetNewPasswordRequest): Promise<FetchUtil.HttpResponse<ResetResponse>> {
    return FetchUtil.post(authenticationApiMatchers.newPassword.replace(":token", token), request);
  }
}

export const authenticationApiMatchers = {
  login: "/api/v1/authentication/login",
  accountRecover: "/api/v1/authentication/account-recover",
  newPassword: "/api/v1/authentication/account-recover/:token/update-password"
};
